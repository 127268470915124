import { yupResolver } from "@hookform/resolvers/yup";
import React from "react";
import { FormProvider, useForm } from "react-hook-form";
import ModalForm from "shared/Modal";
import { EditModalProps } from "../../../app/hooks/useModal";
import { editUserResolver } from "../../../app/resolvers/create-user.resolver";
import { UserDto } from "../../../app/services/dto/user.dto";
import { useUpdateUserByIdMutation } from "../../../app/services/userServices";
import { EditUserForm } from "../Fields/EditUserForm";

export const EditUserModal = ({
	selected,
	setSelected,
	type,
}: EditModalProps & { type: "ADMIN" | "DRIVER" | "CLIENT" }) => {
	if (selected != null) {
		const methods = useForm({ resolver: yupResolver(editUserResolver) });
		const [updateUser] = useUpdateUserByIdMutation();

		const open = React.useMemo(() => Boolean(selected != null), [selected]);
		const handleClose = React.useCallback(() => setSelected(null), [setSelected]);

		const handleSubmit = React.useCallback(
			methods.handleSubmit<UserDto>((params: UserDto) => {
				if (selected) {
					params.id = selected;
				}
				updateUser(params);
				setSelected(null);
			}),
			[]
		);

		const headerProps = {
			title: "Edit User",
			subheader: "#" + selected,
		};

		return (
			<FormProvider {...methods}>
				<ModalForm handleClose={handleClose} handleSubmit={handleSubmit} open={open} headerProps={headerProps}>
					{<EditUserForm id={selected} type={type} />}
				</ModalForm>
			</FormProvider>
		);
	} else {
		return null;
	}
};
