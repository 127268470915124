import { yupResolver } from "@hookform/resolvers/yup";
import { Dialog, DialogActions, DialogContent, DialogTitle } from "@mui/material";
import Button from "@mui/material/Button";
import { addDays, addHours, endOfDay, format, startOfDay } from "date-fns";
import React from "react";
import { FieldValues, FormProvider, useForm, UseFormHandleSubmit } from "react-hook-form";
import { useHistory } from "react-router";
import { toast } from "react-toastify";
import FieldSelect from "shared/Input/Select";
import FieldText from "shared/Input/Text";
import { createBookingResolver } from "../../../app/resolvers/create-booking.resolver";
import { useCreateBookingMutation } from "../../../app/services/bookingServices";
import { useAuth } from "../../../app/services/hooks/useAuth";
import { useGeofence } from "../../../app/services/hooks/useGeofence";
import { useAppDispatch, useAppSelector } from "../../../app/slices/generic";
import { clearMarkers, markerSelector } from "../../../app/slices/markerSlice";
import { entitySelector } from "../../../app/utility/entitySelectors";
import { selectMapper } from "../../../app/utility/selectMapper";
import { SelectOptions } from "../../../config/types";
import FieldDateTime from "../../../shared/Input/Date/DateTime";
import { JobList } from "../../Map/components/JobList";
import { UNIT_MATERIAL_TYPES, UNIT_TYPES } from "../Fields/CreateBookingFields";

export const CreateBookingModal = () => {
	const history = useHistory();
	const methods = useForm({
		resolver: yupResolver(createBookingResolver),
	});
	const { id } = useAuth();
	const dispatch = useAppDispatch();
	const [createBooking, { isSuccess }] = useCreateBookingMutation();
	const [description, setDescription] = React.useState("MATERIAL PICKUP LOAD");
	const markerEntity = useAppSelector(markerSelector);
	const { list: markers } = entitySelector(markerEntity);
	const [open, setOpen] = React.useState(false);
	const { selectStringOptions } = useGeofence();
	const handleOpen = () => setOpen(true);
	const handleClose = () => setOpen(false);
	const handleSubmit = methods.handleSubmit(async (params) => {
		if (!isNaN(parseFloat(params?.numberOfPeople)) && isFinite(params?.numberOfPeople)) {
			params.description = `${params?.description}-${params?.numberOfPeople}`;
		}
		if (params?.dateRequested) {
			await createBooking({
				...params,
				vehicleType: params?.description == "DOCUMENT" ? "CAR" : params.vehicleType,
				jobs: markers.map((m) => ({
					...m,
					description: params?.description || "",
				})),
				user: id,
			})
				.then(() => {
					dispatch(clearMarkers());
					history.push("/home");
				})
				.catch((e) => toast.error(e));
		} else {
			toast.error("date");
		}
	});

	React.useEffect(() => {
		if (isSuccess) {
			handleClose();
		}
	}, [isSuccess, description, setDescription]);

	React.useEffect(() => {
		if (!methods.getValues("dateRequested")) {
			methods.setValue("dateRequested", addDays(addHours(startOfDay(new Date()), 8), 1));
		}
	}, []);

	const descriptionOptions = ["MATERIAL PICKUP LOAD", "DOCUMENT", "SCRAP LOADING", "PEOPLE TRANSPORT"];

	const selectDescription = (data: any) => {
		methods.setValue("description", data.value);
		setDescription(data.value);
		if (description == "DOCUMENT") {
			methods.setValue("vehicleType", "CAR");
		}
	};

	const DescriptionField = (description: any) => {
		if (description == "DOCUMENT") {
			return (
				<FieldSelect
					name="vehicleType"
					options={selectMapper(["CAR"])}
					label="Vehicle Type"
					rules={{ required: "This field is required" }}
					disabled={Boolean(methods.getValues("description") !== "DOCUMENT")}
					defaultValue={"CAR"}
				/>
			);
		}

		if (description == "PEOPLE TRANSPORT") {
			return (
				<>
					<FieldText
						name="numberOfPeople"
						label="Number of People"
						inputProps={{
							type: "number",
						}}
						defaultValue={1}
					/>
					<FieldSelect
						name="vehicleType"
						options={selectMapper(UNIT_TYPES)}
						label="Vehicle Type"
						rules={{ required: "This field is required" }}
						defaultValue={UNIT_TYPES[0]}
					/>
				</>
			);
		}

		return (
			<FieldSelect
				name="vehicleType"
				options={selectMapper(UNIT_MATERIAL_TYPES)}
				label="Vehicle Type"
				rules={{ required: "This field is required" }}
				disabled={Boolean(description !== "DOCUMENT")}
				defaultValue={UNIT_MATERIAL_TYPES[0]}
			/>
		);
	};

	return (
		<FormProvider {...methods}>
			<Button onClick={handleOpen} variant="contained" sx={{ alignItems: "flex-end", marginTop: "auto", flex: 1 }}>
				Finalize Booking
			</Button>
			<Dialog open={open} onClose={handleClose} maxWidth="lg">
				<DialogTitle>Order</DialogTitle>
				<DialogContent>
					<JobList markers={markers} />
					<FieldDateTime
						name="dateRequested"
						label={"Pickup Date"}
						rules={{
							required: "This field is required",
							minLength: { value: 1, message: "Minimun Length is 1" },
						}}
						placeholder={"yyyy-MM-dd hh:mm a"}
						// minDateTime={addDays(startOfDay(new Date()), 1)}
						// maxDateTime={addDays(endOfDay(new Date()), 7)}
						openTo={"hours"}
						defaultValue={format(addDays(startOfDay(new Date()), 1), "yyyy-MM-dd hh:mm a")}
					/>
					{/* <NativeFieldDateTime
            name='dateRequested'
            label={"Pickup Date"}
            rules={{
              required: "This field is required",
              minLength: { value: 1, message: "Minimun Length is 1" },
            }}
            placeholder={"yyyy-MM-dd hh:mm a"}
            minDateTime={addMinutes(new Date(), 10)}
            openTo={"hours"}
            defaultValue={format(
              addMinutes(new Date(), 10),
              "yyyy-MM-dd hh:mm a"
            )}
          /> */}
					<FieldSelect
						name="description"
						label="Description"
						options={selectMapper(descriptionOptions)}
						rules={{ required: "This field is required" }}
						customSelectHook={selectDescription}
						defaultValue={descriptionOptions[0]}
					/>
					{DescriptionField(description)}
					<FieldSelect
						name="chargeProject"
						options={selectStringOptions as SelectOptions[]}
						label="Charge to Project"
						rules={{ required: "This field is required" }}
						isClearable={false}
					/>
					<FieldText
						name="supplierPn"
						label="Supplier Contact Number"
						rules={{
							required: "This field is required",
							pattern: {
								value: /^(?:\971)?(?:50|51|52|55|56|2|3|4|6|7|9)\d{7}$/,
								message: "Please provide a valid PhoneNumber",
							},
						}}
						defaultValue={971}
					/>
				</DialogContent>
				<DialogActions>
					<Button onClick={handleClose}>Cancel</Button>
					<Button onClick={handleSubmit}>Submit Order</Button>
				</DialogActions>
			</Dialog>
		</FormProvider>
	);
};

export interface CreateBookingModalProps {
	submitHandler: UseFormHandleSubmit<FieldValues>;
}
