import { Grid } from "@mui/material";
import { addMinutes } from "date-fns";
import NativeFieldDateTime from "shared/Input/Date/NativeDateTime";

export const UNIT_TYPES = [
	"CAR",
	"PICKUP-1TON",
	"PICKUP-2-4TON",
	"PICKUP-7-10TON",
	"TRAILER",
	// "PICKUP-7T",
	// "PICKUP-DIESEL",
	// "TANKER-SEWAGE",
	// "TANKER-SWEET",
	// "TRAILER-FLATBED",
	// "TRAILER-LOWBED",
];

export const UNIT_MATERIAL_TYPES = [
	"PICKUP-1TON",
	"PICKUP-2-4TON",
	"PICKUP-7-10TON",
	"TRAILER",
	// "PICKUP-7T",
	// "PICKUP-DIESEL",
	// "TANKER-SEWAGE",
	// "TANKER-SWEET",
	// "TRAILER-FLATBED",
	// "TRAILER-LOWBED",
];

export const CreateBookingFields = ({ dateRequested, isDropoff }: CreateBookingFieldProps) => {
	// const list = ["DOCUMENT", "MATERIAL PICKUP LOAD"];

	return (
		<Grid item container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
			<Grid item lg={6}>
				{/* <FieldDateTime */}
				<NativeFieldDateTime
					name="dateRequested"
					label={"Pickup Date"}
					rules={{
						required: "This field is required",
						minLength: { value: 1, message: "Minimun Length is 1" },
					}}
					minDateTime={dateRequested ? addMinutes(dateRequested, 5) : new Date()}
					openTo={dateRequested ? "hours" : "day"}
				/>
			</Grid>
			<Grid item lg={6}>
				{/* <FieldSelect
          name='description'
          label='Description'
          options={selectMapper(list)}
          rules={{ required: "This field is required" }}
        /> */}
				{/* <FieldText
          name='description'
          label='Description'
          inputProps={{
            multiline: true,
          }}
        /> */}
			</Grid>
		</Grid>
	);
};

export interface CreateBookingFieldProps {
	dateRequested?: number | Date;
	isDropoff?: boolean;
}
