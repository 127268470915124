import { useGetBookingByIdQuery } from "../../../app/services/bookingServices";
import { TrackLoader } from "./TrackLoader";

export const BookingLoader = ({ id }: BookingLoaderProps) => {
	const { data } = useGetBookingByIdQuery(Number(id));
	// console.log("booking", data);

	return (
		<>
			{data
				? data?.routes.map((id) => {
						return <TrackLoader key={id} id={id} loadPolyline={true} />;
				  })
				: null}
		</>
	);
};

export interface BookingLoaderProps {
	id: number | string;
}
