import { createEntityAdapter, EntityState } from "@reduxjs/toolkit";
import { api } from "app";
import { UserDto } from "app/services/dto/user.dto";

const URL = "users";

const userServices = api.injectEndpoints({
  endpoints: (build) => ({
    getUsers: build.query<EntityState<UserDto>, void>({
      query: () => ({ url: `${URL}`, method: "GET" }),
      providesTags: ["Users", "Drivers", "Admins", "Clients"],
      transformResponse: (response: { data: UserDto[] }) => {
        return userAdapter.addMany(
          userAdapter.getInitialState(),
          response.data
        )
      }
    }),
    getAdmins: build.query<EntityState<UserDto>, void>({
      query: () => ({ url: `${URL}/admins`, method: "GET" }),
      providesTags: ["Users", "Drivers", "Admins", "Clients"],
      transformResponse: (response: { data: UserDto[] }) => {
        return userAdapter.addMany(
          userAdapter.getInitialState(),
          response.data
        )
      }
    }),
    getClients: build.query<EntityState<UserDto>, void>({
      query: () => ({ url: `${URL}/clients`, method: "GET" }),
      providesTags: [{ type: "Clients" }],
      transformResponse: (response: { data: UserDto[] }) => {
        return userAdapter.addMany(
          userAdapter.getInitialState(),
          response.data
        )
      }
    }),
    getDriver: build.query<EntityState<UserDto>, void>({
      query: () => ({ url: `${URL}/drivers`, method: "GET" }),
      providesTags: ["Users", "Drivers", "Admins", "Clients"],
      transformResponse: (response: { data: UserDto[] }) => {
        return userAdapter.addMany(
          userAdapter.getInitialState(),
          response.data
        )
      }
    }),
    createUser: build.mutation({
      query: (data: UserDto) => ({
        url: `${URL}`,
        method: "POST",
        body: data,
      }),
      invalidatesTags: ["Users", "Drivers", "Admins", "Clients"],
    }),
    updateUserById: build.mutation<UserDto, Partial<UserDto>>({
      query: (params) => ({
        url: `${URL}/${params.id}`, method: "PATCH", headers: {
          options: JSON.stringify({ rid: true })
        }, body: params
      }),
      invalidatesTags: ["Users", "Drivers", "Admins", "Clients"],
    }),
    getUserById: build.query<UserDto, number | string>({
      query: (id) => ({ url: `${URL}/${id}`, method: "GET" }),
      providesTags: ["Users", "Drivers", "Admins", "Clients"],
    }),
    changePassword: build.mutation<UserDto, Partial<UserDto>>({
      query: (params) => ({
        url: `${URL}/${params.id}/update-password`, method: "PATCH", headers: {
          options: JSON.stringify({ rid: true })
        }, body: params
      }),
      invalidatesTags: ["Users", "Drivers", "Admins", "Clients"],
    }),
  }),
  overrideExisting: false,
});

export default userServices;

export const {
  useGetUsersQuery,
  useCreateUserMutation,
  useGetAdminsQuery,
  useGetClientsQuery,
  useGetDriverQuery,
  useUpdateUserByIdMutation,
  useGetUserByIdQuery,
  useChangePasswordMutation
} = userServices;

const userAdapter = createEntityAdapter<UserDto>({});

export type UserActionTypes = "createUser" | "editUser";
