import { UserDto } from "app/services/dto/user.dto";
import { createGenericSlice, GenericState } from "app/slices/generic";
import type { RootState } from "app/store";

const initialState: GenericState<UserDto> = {
  ids: [],
  entities: {},
  selected: null,
  status: "idle",
};

export const userSlice = createGenericSlice({
  name: "users",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    return builder;
  },
});

// Exportable Actions
export const { select: userSelect } = userSlice.actions;

// Commonly Used Data
export const userSelector = ({ users }: RootState) => ({
  lookup: users.entities,
  list: users.ids.map((id) => users.entities[id]),
  ids: users.ids,
  table: users.ids.reduce((acc: UserDto[], id) => {
    acc.push(users.entities[id]);
    return acc;
  }, []),
  selected: users.selected ? users.entities[users.selected] : null,
});

export default userSlice.reducer;
