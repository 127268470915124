import { CreateModalProps } from "app/hooks/useModal";
import { WialonDriverDto } from "app/services/dto/user.dto";
import { useDriversProvider } from "app/services/hooks/useDrivers";
import React from "react";
import { FormProvider, useForm } from "react-hook-form";
import FieldSelect from "shared/Input/Select";
import ModalForm from "shared/Modal";

export function SelectWialonDriverModal({
	selected,
	setSelected,
	setSelectedWialonDriver,
	setSelectedCreate,
}: CreateModalProps & {
	setSelectedWialonDriver: React.Dispatch<React.SetStateAction<WialonDriverDto>>;
	setSelectedCreate: React.Dispatch<React.SetStateAction<boolean>>;
}) {
	const { selectOptions: wialonDrivers } = useDriversProvider();

	const methods = useForm();
	const open = React.useMemo(() => selected, [selected]);
	const handleClose = React.useCallback(() => setSelected(false), [setSelected]);

	const handleSubmit = React.useCallback(
		methods.handleSubmit((values: { driver: string }) => {
			console.log(values);
			const username = values.driver ? values.driver.split(":")[0].trim() : "";
			const name = values.driver ? values.driver : "";
			setSelectedWialonDriver({
				username,
				name,
			});
			setSelected(false);
			setSelectedCreate(true);
		}),
		[]
	);

	// const handleSubmit = React.useCallback(function handleSubmit(params: WialonDriverDto) {
	// 	// setSelectedWialonDriver({ ...params });
	// 	setSelectedWialonDriver({ ...params });
	// 	setSelected(false);
	// 	setSelectedCreate(true);
	// }, []);

	const headerProps = {
		title: "Select Driver",
		subheader: "#" + selected,
	};
	return (
		<FormProvider {...methods}>
			<ModalForm handleClose={handleClose} handleSubmit={handleSubmit} open={open} headerProps={headerProps}>
				{/* {wialonDrivers ? (
					<Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
						<Grid item sm={12}> */}
				<FieldSelect
					name="driver"
					// options={orderBy(wialonDrivers, ["color"], ["desc"])}
					options={
						(wialonDrivers as unknown as { value: string; label: string; extra: { ID: string; NAME: string } }[]).map(
							(d) => ({
								value: d.extra.ID && d.extra.NAME ? `${d.extra.ID}:${d.extra.NAME}` : d.label,
								label: d.label,
							})
						) ?? []
					}
					label="Driver"
					rules={{ required: false }}
					// customSelectHook={setDriver}
					isClearable={true}
				/>
				{/* </Grid>
					</Grid>
				) : (
					<LinearProgress />
				)} */}
				{/* Hi */}
			</ModalForm>
		</FormProvider>
	);
}
