import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "app/store";
import { GenericState } from "./generic";

export interface MarkerProps {
  id?: number
  lat: number
  lng: number
  order: number
  description: string
  dateRequested: Date
  chargeProject: string
  address: string
  project: string
}

export interface OrderDto {
  description: string
  dateRequested: Date
  chargeProject: string
  address: string
  project: string
}

const initialState: GenericState<MarkerProps> = {
  ids: [],
  entities: {},
  selected: null,
  status: "idle",
};

export const markerSlice = createSlice({
  name: "markers",
  initialState: initialState,
  reducers: {
    openMarker: (state, data: { payload: MarkerProps }) => {
      console.log(state, data)
    },
    clearMarkers: (state) => {
      Object.assign(state, initialState)
    },
    addMarker: (state, { payload }: PayloadAction<MarkerProps>) => {
      const hasLatLng = ["lat", "lng"].every(term => Object.keys(payload).includes(term))
      if (hasLatLng) {
        const numberOfOrders = state.ids.length || 0
        state.ids.push(numberOfOrders)
        state.entities[String(numberOfOrders)] = { ...payload, order: numberOfOrders, }
      }
    },
    reorder: (state, { payload }: PayloadAction<ReorderProps>) => {
      const isValid = ["startIndex", "endIndex"].every(term => Object.keys(payload).includes(term))
      if (isValid) {
        const { startIndex, endIndex } = payload

        const result = Object.values(state.entities)
        const resultDates = result.map(item => item?.dateRequested)
        const [removed] = result.splice(startIndex, 1);
        result.splice(endIndex, 0, removed);

        const { entities, ids } = result.reduce<{ entities: Record<string, MarkerProps>, ids: number[] }>((acc, item, index) => {
          acc.entities[String(index)] = { ...item, order: index, dateRequested: resultDates[index] }
          acc.ids.push(index)
          return acc
        }, {
          entities: {},
          ids: []
        })

        if (ids && entities) {
          state.entities = entities
          state.ids = ids
        }
      }
    },
    removeMarker: (state, { payload: order }: PayloadAction<number>) => {
      const result = Object.values(state.entities)
      const index = result.findIndex((item) => item.order == order)
      result.splice(index || order, 1);
      const resultDates = result.map(item => item?.dateRequested)

      const { entities, ids } = result.reduce<{ entities: Record<string, MarkerProps>, ids: number[] }>((acc, item, index) => {
        acc.entities[String(index)] = { ...item, order: index, dateRequested: resultDates[index] }
        acc.ids.push(index)
        return acc
      }, {
        entities: {},
        ids: []
      })

      if (ids && entities) {
        state.entities = entities
        state.ids = ids
      }
    },
  },
});

// Exportable Actions
export const { openMarker, addMarker, reorder, removeMarker, clearMarkers } = markerSlice.actions;

// Commonly Used Data
export const markerSelector = ({ markers }: RootState) => markers;

export default markerSlice.reducer;

interface ReorderProps {
  startIndex: number,
  endIndex: number
}
