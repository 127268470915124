import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { RootState } from "./store";

export const baseQuery = fetchBaseQuery({
	baseUrl: process.env.REACT_APP_API_URL,
	prepareHeaders: (headers, { getState }) => {
		const { access_token, wialon_sid, id, role } = (getState() as RootState).auth;

		if (access_token) {
			headers.set("authorization", `Bearer ${access_token}`);
		}
		if (wialon_sid) {
			headers.set("x-wialon-sid", wialon_sid);
		}
		if (id) {
			headers.set("x-id", String(id));
		}
		if (["ADMIN", "SUPERUSER"].includes(role)) {
			headers.set("x-ar", role);
		}
		if (["DRIVER"].includes(role)) {
			headers.set("x-dr", role);
		}

		return headers;
	},
});

export const USER_TAGS = ["Users", "Clients", "Admins", "Drivers"];

export const BOOKING_TAGS = ["Bookings", "Pending Bookings", "Booked Bookings", "Completed Bookings"];

export const TAG_TYPES = ["Auth", "Units", "Logs", "Jobs", ...USER_TAGS, ...BOOKING_TAGS];

export const TAG_TYPE = TAG_TYPES.map((item) => ({ type: item }));

export const api = createApi({
	reducerPath: "api",
	tagTypes: TAG_TYPES,
	baseQuery: baseQuery,
	endpoints: () => ({}),
});
