import { isValid } from "date-fns";
import moment from "moment";
import * as yup from "yup";

const defaultRequired = "This field is required";

export const createBookingResolver = yup.object().shape({
	dateRequested: yup
		.date()
		.typeError("Invalid Date")
		.required(defaultRequired)
		// .min(moment(new Date()).add(1, "day").startOf("day"), "Requested Date Too Early")
		// .max(moment(new Date()).add(8, "day").endOf("day"), "Requested Date Too Late")
		// .when(([other], schema) => (other === 4 ? schema.max(6) : schema))
		.transform((_currentData, originalData) => {
			if (!originalData) {
				return originalData;
			} else if (moment(originalData).isValid()) {
				return moment(originalData).toDate();
			} else if (typeof originalData == "object" && isValid(originalData)) {
				return new Date((originalData as Date).toISOString());
			} else {
				return null;
			}
		}),
	description: yup.string().required(defaultRequired).trim(),
	chargeProject: yup.string().required(defaultRequired).trim(),
	vehicleType: yup.string().required(defaultRequired),
});
