import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import {
  Avatar,
  Card,
  CardContent,
  CardHeader,
  Container,
} from "@mui/material";
import { useAuth } from "../../app/services/hooks/useAuth";
import { ChangePasswordForm } from "../User/Form/ChangePasswordForm";
const Profile = () => {
  const { name, role } = useAuth();
  return (
    <Container>
      <Card>
        <CardHeader
          avatar={
            <Avatar>
              <AccountCircleIcon />
            </Avatar>
          }
          title={name}
          subheader={role}
        />
        <CardContent>
          <ChangePasswordForm />
        </CardContent>
      </Card>
    </Container>
  );
};

export default Profile;
