import L from "leaflet";
import React from "react";
import { Marker, useMap } from "react-leaflet";
import { useGetRouteByIdQuery } from "../../../app/services/routeServices";
import { PolylineLoader } from "./PolyLineLoader";
import dropoffIcon from "./wh.png";
import pickupIcon from "./whb.png";

const PickupIcon = new L.Icon({
	iconUrl: pickupIcon,
	iconRetinaUrl: pickupIcon,
	iconSize: new L.Point(35, 45),
});

const DropoffIcon = new L.Icon({
	iconUrl: dropoffIcon,
	iconRetinaUrl: dropoffIcon,
	iconSize: new L.Point(35, 45),
});

export const TrackLoader = ({ id, loadPolyline }: TrackLoaderProps) => {
	const map = useMap();
	const { data: track } = useGetRouteByIdQuery(id);
	// console.log("track", track);
	const isSameLat = track?.start?.lat === track?.finish?.lat;
	const isSameLng = track?.start?.lng === track?.finish?.lng;
	const isNoRoute = isSameLat && isSameLng;
	const isValid = track?.start && track?.finish && !isNoRoute;

	React.useEffect(() => {
		if (isValid) {
			map.setView(track.start);
		}
	}, [track]);

	if (isValid) {
		const start = track.start;
		const finish = track.finish;

		return (
			<>
				<Marker position={[start.lat, start.lng]} icon={PickupIcon}></Marker>
				<Marker position={[finish.lat, finish.lng]} icon={DropoffIcon}></Marker>
				{loadPolyline && <PolylineLoader start={start} finish={finish} />}
			</>
		);
	} else {
		return null;
	}
};

export interface TrackLoaderProps {
	id: number;
	loadPolyline?: boolean;
}
