import { Grid } from "@mui/material";
import { Map } from "leaflet";
import { useGetBookingByIdQuery } from "../../../app/services/bookingServices";
import { ProjectLocationDto } from "../../../app/slices/dto/select-marker.dto";
import { JobListReview } from "./JobListReview";

export const BookingReviewPanel = ({ id, map }: BookingReviewPanelProps) => {
  const { data } = useGetBookingByIdQuery(Number(id));

  return (
    <Grid
      item
      container
      md={4}
      rowSpacing={2}
      direction='column'
      style={{
        display: "flex",
        flexDirection: "column",
        flex: "1 1 auto",
      }}
    >
      <Grid
        style={{ height: "100%", display: "flex", flexDirection: "column" }}
      >
        {map && data ? (
          <JobListReview ids={data.routes} map={map} booking={data} />
        ) : null}
      </Grid>
    </Grid>
  );
};

export type BookingReviewPanelProps = {
  id: number | string;
  map: Map | null;
} & Partial<ProjectLocationDto>;
