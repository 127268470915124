import { Grid, LinearProgress, TextField } from "@mui/material";
import { useGetBookingByIdQuery } from "../../../app/services/bookingServices";

export const BookingDetailFields = ({ id }: CreateBookingFieldProps) => {
  const { data, isFetching } = useGetBookingByIdQuery(id as number);

  return isFetching ? (
    <LinearProgress />
  ) : (
    <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
      <Grid item sm={6}>
        <TextField
          label='Pickup Date'
          defaultValue='NaN'
          InputProps={{
            readOnly: true,
          }}
          value={data?.dateRequested || null}
          variant='standard'
          fullWidth={true}
        />
      </Grid>
      <Grid item sm={6}>
        <TextField
          label='Vehicle Type'
          defaultValue='NaN'
          InputProps={{
            readOnly: true,
          }}
          value={data?.vehicleType || null}
          variant='standard'
          fullWidth={true}
        />
      </Grid>
      <Grid item sm={6}>
        <TextField
          label='Project'
          defaultValue='NaN'
          InputProps={{
            readOnly: true,
          }}
          value={data?.chargeProject || null}
          variant='standard'
          fullWidth={true}
        />
      </Grid>
      <Grid item sm={6}>
        <TextField
          label='Description'
          defaultValue='NaN'
          InputProps={{
            readOnly: true,
          }}
          value={data?.description || null}
          variant='standard'
          fullWidth={true}
        />
      </Grid>
    </Grid>
  );
};

export interface CreateBookingFieldProps {
  id?: number;
}
