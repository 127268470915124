import { AllInbox } from "@mui/icons-material";
import AssignmentIcon from "@mui/icons-material/Assignment";
import AssignmentLateIcon from "@mui/icons-material/AssignmentLate";
import AssignmentTurnedInIcon from "@mui/icons-material/AssignmentTurnedIn";
import BorderColorIcon from "@mui/icons-material/BorderColor";
import DashboardIcon from "@mui/icons-material/Dashboard";
import PeopleIcon from "@mui/icons-material/People";
import StoreIcon from "@mui/icons-material/Store";
import DirectionsCarIcon from "@mui/icons-material/DirectionsCar";
import SummarizeIcon from "@mui/icons-material/Summarize";
import { Badge, List } from "@mui/material";
import React from "react";
import LinkedItem, { LinkedItemProps } from "shared/Display/Link/Drawer";
import NestedLinkedItem from "shared/Display/Link/Drawer/Nested";
import { useGetBookingCountQuery } from "../../app/services/bookingServices";

const DrawerComponents = ({ role }: { role: string }) => {
	const { data } = useGetBookingCountQuery();

	const pendingCount = React.useMemo(() => {
		let init = 0;
		if (data) {
			data.forEach(({ count, status }) => {
				if (["PENDING", "TO_CANCEL"].includes(status)) {
					init += Number(count);
				}
			});
		}
		return init;
	}, [data]);

	const route = [
		{
			path: `/home`,
			icon: <DashboardIcon />,
			title: "Home",
			isAllowed: ["ADMIN", "SUPERUSER", "CLIENT"].includes(role),
		},
		{
			title: "Bookings",
			route: [
				{
					path: `/bookings/table/pending`,
					icon: pendingCount ? (
						<Badge badgeContent={pendingCount} color="error">
							<AssignmentLateIcon />
						</Badge>
					) : (
						<AssignmentLateIcon />
					),
					title: "Pending",
					isAllowed: ["ADMIN", "SUPERUSER", "CLIENT"].includes(role),
				},
				{
					path: `/bookings/table/booked`,
					icon: <AssignmentIcon />,
					title: "Booked",
					isAllowed: ["ADMIN", "SUPERUSER"].includes(role),
				},
				{
					path: `/bookings/table/completed`,
					icon: <AssignmentTurnedInIcon />,
					title: "Completed",
					isAllowed: ["ADMIN", "SUPERUSER", "CLIENT"].includes(role),
				},
				{
					path: `/bookings/summary`,
					icon: <SummarizeIcon />,
					title: "Trip Summary",
					isAllowed: ["ADMIN", "SUPERUSER", "CLIENT"].includes(role),
				},
				{
					path: `/bookings/create`,
					icon: <BorderColorIcon />,
					title: "Book a Trip",
					isAllowed: ["ADMIN", "SUPERUSER", "CLIENT"].includes(role),
				},
			],
			isAllowed: ["ADMIN", "SUPERUSER", "CLIENT"].includes(role),
			isNested: true,
			icon: <AllInbox />,
		},
		{
			hasDivider: true,
			path: `/admins/table`,
			icon: <PeopleIcon />,
			title: "Admins",
			isAllowed: ["ADMIN", "SUPERUSER"].includes(role),
		},
		{
			path: `/clients/table`,
			icon: <StoreIcon />,
			title: "Clients",
			isAllowed: ["ADMIN", "SUPERUSER"].includes(role),
		},
		{
			path: `/drivers/table`,
			icon: <DirectionsCarIcon />,
			title: "Drivers",
			isAllowed: ["ADMIN", "SUPERUSER"].includes(role),
		},
		{
			title: "Reports",
			route: [
				{
					path: `/reports/general`,
					icon: <AssignmentIcon />,
					title: "General Report",
					isAllowed: ["ADMIN", "SUPERUSER", "CLIENT"].includes(role),
				},
				{
					path: `/reports/driver`,
					icon: <AssignmentIcon />,
					title: "Driver Activity",
					isAllowed: ["ADMIN", "SUPERUSER"].includes(role),
				},
			],
			isAllowed: ["ADMIN", "SUPERUSER", "CLIENT"].includes(role),
			isNested: true,
			icon: <AssignmentIcon />,
		},
		// {
		//   hasDivider: true,
		//   path: `/vehicles/table`,
		//   icon: <DriveEtaIcon />,
		//   title: "Vehicles",
		//   isAllowed: ["ADMIN", "SUPERUSER"].includes(role),
		// },
	];

	return (
		<List>
			{route.map((item, index) => {
				if (item.route && item.isNested) {
					return <NestedLinkedItem {...item} key={index} />;
				} else if (item.isAllowed) {
					return <LinkedItem {...item} key={index} />;
				}
				return null;
			})}
		</List>
	);
};

export interface DrawerComponentsProps {
	role?: string;
	handleLogout?: () => void;
	route: LinkedItemProps[];
	isAllowed: boolean;
}

export default DrawerComponents;
