import { EntityState } from "@reduxjs/toolkit";
import { selectMapper } from "app/utility/selectMapper";
import React from "react";

export const entitySelector = <Entity>(
  { entities, ids }: EntityState<Entity> = { entities: {}, ids: [] }
) => ({
  lookup: React.useCallback(
    (term: keyof Entity) =>
      ids.reduce<Record<string, unknown>>((acc, id) => {
        const entity = entities[id];
        if (entity) {
          acc[id] = entity[term];
        }
        return acc;
      }, {}),
    [entities, ids]
  ),
  selectOptions: React.useMemo(
    () =>
      selectMapper(entities, {
        label: "type",
        value: "id",
      }),
    [entities]
  ),
  list: React.useMemo(
    () =>
      ids.reduce<Entity[]>((acc, id) => {
        const entity = entities[id]
        if (entity) {
          acc.push(entity)
        }
        return acc;
      }, []),
    [ids, entities]
  ),
  ids: React.useMemo(() => ids, [ids]),
  table: React.useMemo(() => ids.map((id) => entities[id]), [ids, entities]),
});
