import { configureStore } from "@reduxjs/toolkit";
import { setupListeners } from "@reduxjs/toolkit/dist/query";
import { api } from "app";
import authSlice from "app/slices/authSlice";
import pageSlice from "app/slices/pageSlice";
import userSlice from "app/slices/userSlice";
import { errorHandleMiddleware } from "./middlewares/errorHandler";
import { responseUnwrapperMiddleware } from "./middlewares/responseUnwrapper";
import markerSlice from "./slices/markerSlice";

const useStore = () => {
  const store = configureStore({
    reducer: {
      auth: authSlice,
      users: userSlice,
      pages: pageSlice,
      markers: markerSlice,
      [api.reducerPath]: api.reducer,
    },
    middleware: (getDefaultMiddleware) =>
      getDefaultMiddleware({ serializableCheck: false }).concat([
        api.middleware,
        errorHandleMiddleware,
        responseUnwrapperMiddleware,
      ]),
  });

  setupListeners(store.dispatch);
  return store;
};

export const store = useStore();

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
