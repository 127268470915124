import { Button, Container, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";

const Page404 = () => {
  const { t } = useTranslation();
  return (
    <Container
      sx={{
        justifyContent: "center",
        alignItems: "center",
        flexDirection: "column",
        display: "flex",
      }}
    >
      <img
        src={`img/LOGO.png`}
        srcSet={`img/LOGO.png`}
        alt={"404"}
        loading='lazy'
        height={450}
      />
      <Typography
        variant='h1'
        sx={{
          marginTop: 5,
          fontSize: `calc(1.3rem + .6vw)`,
          fontFamily: "Poppins,Helvetica,sans-serif",
          fontWeight: 500,
          color: "#A3A3C7",
        }}
      >
        {t("required_login")}
      </Typography>
      <Typography
        variant='h1'
        sx={{
          marginBottom: 5,
          fontSize: `calc(1.3rem + .6vw)`,
          fontFamily: "Poppins,Helvetica,sans-serif",
          fontWeight: 500,
          color: "#A3A3C7",
        }}
      >
        {t("required_login_return")}
      </Typography>
      <Button
        variant='contained'
        color='info'
        size='large'
        href='http://wialon.atsuae.net'
      >
        Go Back
      </Button>
    </Container>
  );
};

export default Page404;
