import { entitySelector } from "app/utility/entitySelectors";
import React from "react";
import { useGetBookedBookingsQuery } from "../bookingServices";

export const useBookedBookingProvider = () => {
  const props = useGetBookedBookingsQuery(undefined, {
    selectFromResult: ({ data, ...params }) => ({
      ...entitySelector(data),
      ...params,
    }),
  });

  return React.useMemo(() => props, [props]);
};

export const BookedBookingContext = React.createContext<ReturnType<
  typeof useBookedBookingProvider
> | null>(null);

export const BookedBookingProvider = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const props = useBookedBookingProvider();
  return (
    <BookedBookingContext.Provider value={props}>
      {children}
    </BookedBookingContext.Provider>
  );
};

export const useBookedBooking = () => {
  return React.useContext(BookedBookingContext) as ReturnType<
    typeof useBookedBookingProvider
  >;
};
