import { isValid } from "date-fns";
import moment from "moment";
import * as yup from "yup";

const defaultRequired = "This field is required";

export const createOrderResolver = yup.object().shape({
  dateRequested: yup
    .date()
    .typeError("Invalid Date")
    .required(defaultRequired)
    .transform((_currentData, originalData) => {
      if (!originalData) {
        return originalData;
      } else if (moment(originalData).isValid()) {
        return moment(originalData).toDate();
      } else if (typeof originalData == "object" && isValid(originalData)) {
        return new Date((originalData as Date).toISOString());
      } else {
        return originalData;
      }
    }),
  // description: yup.string().required(defaultRequired).trim(),
  // chargeProject: yup.string().required(defaultRequired).trim(),
  // vehicleType: yup.string().required(defaultRequired),
});
