import { UnitDriverFields } from "../../Unit/Fields/UnitDriverFields";
import { BookingProvider } from "../Providers/BookingProvider";

export const ReviewBookingForm = ({ id }: { id: number }) => {
  return (
    <BookingProvider id={id}>
      <UnitDriverFields />
    </BookingProvider>
  );
};
