import {
  Timeline,
  TimelineConnector,
  TimelineContent,
  TimelineDot,
  TimelineItem,
  TimelineOppositeContent,
  TimelineSeparator,
} from "@mui/lab";
import { CardHeader, Chip, Grid, Typography } from "@mui/material";
import { format } from "date-fns";
import { LatLngLiteral } from "leaflet";
import {
  DragDropContext,
  Draggable,
  Droppable,
  DropResult,
} from "react-beautiful-dnd";
import { GisRouteWialonDto } from "../../../app/services/dto/gis-route.dto";
import { ProjectLocationDto } from "../../../app/slices/dto/select-marker.dto";
import { useAppDispatch } from "../../../app/slices/generic";
import { MarkerProps, reorder } from "../../../app/slices/markerSlice";
import { SelectOptions } from "../../../config/types";

export const JobList = ({ markers }: { markers: MarkerProps[] }) => {
  // a little function to help us with reordering the result
  const dispatch = useAppDispatch();
  const onDragEnd = (result: DropResult) => {
    if (!result.destination) {
      return;
    }

    dispatch(
      reorder({
        startIndex: result.source.index,
        endIndex: result.destination.index,
      })
    );
  };

  return (
    <>
      <Timeline position='right'>
        <DragDropContext onDragEnd={onDragEnd}>
          <Droppable droppableId='droppable'>
            {(provided) => (
              <div ref={provided.innerRef} {...provided.droppableProps}>
                {markers.map((item, index) =>
                  item ? (
                    <Draggable
                      key={String(item?.dateRequested) + "-" + index}
                      draggableId={String(
                        String(item?.dateRequested) + "-" + index
                      )}
                      index={index}
                    >
                      {(provided, snapshot) => {
                        return (
                          <TimelineItem
                            ref={provided.innerRef}
                            {...provided.draggableProps}
                            {...provided.dragHandleProps}
                            key={String(item?.dateRequested) + "-" + index}
                          >
                            <TimelineOppositeContent
                              color='text.secondary'
                              style={{
                                maxWidth: "125px",
                              }}
                            >
                              {item?.dateRequested &&
                                String(
                                  format(
                                    new Date(item?.dateRequested),
                                    "MMM dd, yyyy"
                                  )
                                )}
                            </TimelineOppositeContent>
                            <TimelineSeparator>
                              <TimelineDot />
                              <TimelineConnector />
                            </TimelineSeparator>
                            <TimelineContent>
                              <CardHeader
                                title={
                                  <Grid>
                                    <Typography variant='subtitle2'>
                                      {item?.project}
                                    </Typography>
                                    <Grid>
                                      <Chip
                                        label={index ? "DROPOFF" : "PICKUP"}
                                        color={index ? "error" : "success"}
                                      />
                                    </Grid>
                                  </Grid>
                                }
                                subheader={item?.address}
                              />
                            </TimelineContent>
                          </TimelineItem>
                        );
                      }}
                    </Draggable>
                  ) : null
                )}
                {provided.placeholder}
              </div>
            )}
          </Droppable>
        </DragDropContext>
      </Timeline>
    </>
  );
};

export type LocationDetailPanelProps = {
  latLng: LatLngLiteral;
} & Partial<ProjectLocationDto>;

export interface LocationDetailsDto {
  latLng: LatLngLiteral;
  project?: SelectOptions | null;
  data?: GisRouteWialonDto;
}
