import { useGetBookingByIdQuery } from "../../../app/services/bookingServices";
import { ProjectLocationDto } from "../../../app/slices/dto/select-marker.dto";
import { JobListSummary } from "./JobListSummary";

export const BookingReviewPanelModal = ({
  id,
}: BookingReviewPanelModalProps) => {
  const { data } = useGetBookingByIdQuery(Number(id));

  return data ? <JobListSummary ids={data.routes} booking={data} /> : null;
};

export type BookingReviewPanelModalProps = {
  id: number | string;
} & Partial<ProjectLocationDto>;
