import AdapterDateFns from "@mui/lab/AdapterDateFns";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import { store } from "app/store";
import App from "features/App";
import "index.css";
import React from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import "react-toastify/dist/ReactToastify.css";
import reportWebVitals from "reportWebVitals";
import { SseProvider } from "./app/hooks/useSse";
import { AuthProvider } from "./app/services/hooks/useAuth";

ReactDOM.render(
  <React.StrictMode>
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <Provider store={store}>
        <SseProvider>
          <AuthProvider>
            <App />
          </AuthProvider>
        </SseProvider>
      </Provider>
    </LocalizationProvider>
  </React.StrictMode>,
  document.getElementById("root")
);

reportWebVitals();
