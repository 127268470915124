import { Link, Typography } from "@mui/material";

export const Copyright = () => {
    return <Typography variant="body2" color="textSecondary" align="center" sx={{ pt: 4 }}>
        {'Copyright © '}
        <Link color="inherit" href="https://atsuae.net/">
            Advance Telematics Solutions LLC
        </Link>{' '}
        {new Date().getFullYear()}
        {'.'}
    </Typography>
}

export default Copyright;