import React from "react";
import { TableProps } from "shared/Table";
import { CustomFilterComponent } from "shared/Table/CustomFilterComponent";
import {
  customDateFilter,
  customTextAreaFilter,
} from "shared/Table/customFilters";
import { BookingDto } from "../../../app/services/dto/booking.dto";
import { useBookedBooking } from "../../../app/services/hooks/useBookedBooking";
import { useCompletedBooking } from "../../../app/services/hooks/useCompletedBooking";
import { usePendingBooking } from "../../../app/services/hooks/usePendingBooking";

export const bookingTableProps = ({
  table,
  refetch,
  title,
  actions,
  role,
  columns,
}: BookingTableProps): TableProps<BookingDto> => {
  const baseActions = React.useMemo(() => {
    if (role && refetch) {
      const defaultActions = [
        {
          icon: "refresh",
          tooltip: "Refresh",
          isFreeAction: true,
          onClick: refetch,
        },
        // {
        //   icon: "content_copy",
        //   tooltip: "Copy to Clipboard",
        //   isFreeAction: true,
        //   onClick: refetch,
        // },
      ];

      return actions ? [...defaultActions, ...actions] : defaultActions;
    } else {
      return [];
    }
  }, [role]);

  return {
    data: table as [],
    columns: columns || [
      {
        id: "id",
        title: "Booking ID",
        field: "id",
        customFilterAndSearch: customTextAreaFilter,
        defaultSort: "desc",
      },
      {
        id: "id",
        title: "Status",
        field: "status",
        cellStyle: (rowData: string) => {
          return {
            color:
              rowData == "COMPLETED"
                ? "green"
                : rowData == "REJECTED" || rowData == "CANCELLED"
                ? "red"
                : "black",
          };
        },
      },
      {
        id: "id",
        title: "Requested Date",
        field: "dateRequested",
        type: "date",
        // customFilterAndSearch: customDateFilter,
        // filterComponent: CustomFilterComponent,
      },
      { id: "id", title: "Requested By", field: "user.name" },
      {
        id: "id",
        title: "Vehicle Type",
        field: "vehicleType",
      },
      { id: "id", title: "Driver Name", field: "driver.name" },
      {
        id: "id",
        title: "Charge to Project",
        field: "chargeProject",
        hiddenByColumnsButton: true,
        hidden: true,
        export: true,
      },
      {
        id: "id",
        title: "Pickup Location",
        field: "routes[0].start.address",
        hiddenByColumnsButton: true,
        hidden: true,
        export: true,
      },
      {
        id: "id",
        title: "Dropoff Location",
        field: "routes[0].finish.address",
        hiddenByColumnsButton: true,
        hidden: true,
        export: true,
      },
      {
        id: "id",
        title: "Pickup Project",
        field: "routes[0].start.project",
      },
      {
        id: "id",
        title: "Dropoff Project",
        field: "routes[0].finish.project",
      },
      {
        id: "id",
        title: "Requested Pickup Time",
        field: "routes[0].start.timeRequested",
        type: "datetime",
        customFilterAndSearch: customDateFilter,
        filterComponent: CustomFilterComponent,
        hiddenByColumnsButton: true,
        hidden: true,
        export: true,
      },
      {
        id: "id",
        title: "Actual Pickup Time",
        field: "routes[0].start.timeFinish",
        type: "datetime",
        customFilterAndSearch: customDateFilter,
        filterComponent: CustomFilterComponent,
        hiddenByColumnsButton: true,
        hidden: true,
        export: true,
      },
      {
        id: "id",
        title: "Requested Dropoff Time",
        field: "routes[0].finish.timeRequested",
        type: "datetime",
        customFilterAndSearch: customDateFilter,
        filterComponent: CustomFilterComponent,
        hiddenByColumnsButton: true,
        hidden: true,
        export: true,
      },
      {
        id: "id",
        title: "Actual Dropoff Time",
        field: "routes[0].finish.timeFinish",
        type: "datetime",
        customFilterAndSearch: customDateFilter,
        filterComponent: CustomFilterComponent,
        hiddenByColumnsButton: true,
        hidden: true,
        export: true,
      },
      {
        id: "id",
        title: "Creation Date",
        field: "createdAt",
        type: "datetime",
        hiddenByColumnsButton: true,
        hidden: true,
        export: true,
      },
      {
        id: "id",
        title: "Remarks",
        field: "cancelReason",
        hiddenByColumnsButton: true,
        hidden: true,
        export: true,
      },
    ],
    getData: refetch,
    actions: baseActions,
    title: title || "Details",
  };
};

export interface BookingTableInterface extends Partial<TableProps<BookingDto>> {
  tableHook?:
    | typeof useBookedBooking
    | typeof usePendingBooking
    | typeof useCompletedBooking;
  table?: (BookingDto | undefined)[];
  refetch?: () => void;
  title: string;
}

export type BookingTableProps = BookingTableInterface;
