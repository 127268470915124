import { Grid } from "@mui/material";
import FieldText from "shared/Input/Text";

export const PasswordFields = () => {
  return (
    <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
      <Grid item sm={6}>
        <FieldText
          name='password'
          label='Password'
          rules={{ required: "This field is required" }}
          type='password'
        />
      </Grid>
      <Grid item sm={6}>
        <FieldText
          name='passwordConfirmation'
          label='Confirm Password'
          rules={{ required: "This field is required" }}
          type='password'
        />
      </Grid>
    </Grid>
  );
};

export interface UnitDriverFieldsProps {
  setDriver?: (param: number) => void;
  setBooking?: (param: number) => void;
  setVehicle?: (param: number) => void;
  driverOptions: any[];
  vehicleOptions: any[];
}
