import { Column } from "@material-table/core";
import { isWithinInterval, parseISO } from "date-fns";

// eslint-disable-next-line @typescript-eslint/ban-types
export const customTextAreaFilter = <RowData extends object>(
  term: unknown,
  rowData: RowData,
  columnDef: Column<RowData>
) => {
  const searchField = columnDef.field as keyof RowData;
  const target = rowData[searchField];
  const terms = (term as string).match(/[^\r\n, ]+/g) || [];
  return terms.some((item: string) => String(target).trim().includes(item));
};

// eslint-disable-next-line @typescript-eslint/ban-types
export const customDateFilter = <RowData extends object>(
  term: unknown,
  rowData: RowData,
  columnDef: Column<RowData>
) => {
  const searchField = columnDef.field as keyof RowData;
  const target = rowData[searchField];
  if (typeof term == "object") {
    const isValidInterval = (term as Interval).start || (term as Interval).end;
    if (isValidInterval) {
      return target
        ? isWithinInterval(parseISO(String(target)), term as Interval)
        : false;
    }
    return false;
  } else {
    const noMatch = String(target).indexOf(term as string) !== -1;
    return noMatch;
  }
};
