import { LatLngLiteral } from "leaflet";
import React, { useEffect } from "react";
import { BookingDto } from "../../../app/services/dto/booking.dto";
import { GisRouteWialonDto } from "../../../app/services/dto/gis-route.dto";
import { useGetJobByIdQuery } from "../../../app/services/jobServices";
import {
  useGetGisGeocodeQuery,
  useGetRouteByIdQuery,
  useLazyGetGisRouteQuery,
} from "../../../app/services/routeServices";
import { ProjectLocationDto } from "../../../app/slices/dto/select-marker.dto";
import { SelectOptions } from "../../../config/types";
import { LocationDetailPanelSummaryViewer } from "./LocationDetailPanelSummaryViewer";

export const JobListSummary = ({
  ids,
  booking,
}: {
  ids: (string | number)[];
  booking?: BookingDto;
}) => {
  const { data: route } = useGetRouteByIdQuery(ids[0]);
  const { data: start } = useGetJobByIdQuery(route?.start?.id as number);
  const { data: finish } = useGetJobByIdQuery(route?.finish?.id as number);

  const { data: geoAddress } = useGetGisGeocodeQuery({
    lat: route?.lng || 25.107840896383536,
    lng: route?.lat || 55.24619296386791,
  });

  const [trigger, result] = useLazyGetGisRouteQuery();
  const [trigger2, result2] = useLazyGetGisRouteQuery();

  useEffect(() => {
    if (route) {
      trigger({
        lat1: route?.start.lat,
        lon1: route?.start.lng,
        lat2: route?.finish.lat,
        lon2: route?.finish.lng,
      });
      trigger2({
        lat1: route?.lng as number,
        lon1: route?.lat as number,
        lat2: route?.start.lat,
        lon2: route?.start.lng,
      });
    }
  }, [route]);

  const markers = route
    ? [
        {
          ...route.start,
          st: "start",
        },
        {
          ...route.finish,
          st: "finish",
        },
        {
          timeStart: route?.timeStart,
          action: "TRIP SUMMARY",
          timeFinish: route?.timeFinish,
          distanceFromTrip: result?.data?.distance?.text,
          distanceFromStart: result2?.data?.distance?.text,
          distanceFromTotal:
            ((result?.data?.distance?.value || 0) +
              (result2?.data?.distance?.value || 0)) /
            1000,
          geoAddress,
        },
      ]
    : [];

  return (
    <>
      {route?.start?.id && (
        <LocationDetailPanelSummaryViewer
          id={route?.start?.id}
          booking={booking}
          markers={markers}
          route={route}
          start={start}
          finish={finish}
          distanceFromTrip={result?.data?.distance?.text}
          distanceFromStart={result2?.data?.distance?.text}
          distanceFromTotal={
            (
              ((result?.data?.distance?.value || 0) +
                (result2?.data?.distance?.value || 0)) /
                1000 || 0
            )?.toFixed(2) + " km"
          }
        />
      )}
    </>
  );
};

export type LocationDetailPanelProps = {
  latLng: LatLngLiteral;
} & Partial<ProjectLocationDto>;

export interface LocationDetailsDto {
  latLng: LatLngLiteral;
  project?: SelectOptions | null;
  route?: GisRouteWialonDto;
}
