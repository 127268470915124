import * as yup from "yup";

const defaultRequired = "This field is required";
const phoneRegExp = /^(?:\971)?(?:50|51|52|55|56|2|3|4|6|7|9)\d{7}$/;

export const createUserResolver = yup.object().shape({
	name: yup.string().required(defaultRequired).trim(),
	username: yup.string().required(defaultRequired).trim(),
	// phone: yup.string().matches(phoneRegExp, 'Phone number is not valid'),
	// email: yup.string().email().required(defaultRequired).trim(),
	password: yup.string().required("Password is required"),
	passwordConfirmation: yup
		.string()
		.oneOf([yup.ref("password"), null], "Passwords must match")
		.required(defaultRequired),
});

export const editUserResolver = yup.object().shape({
	name: yup.string().required(defaultRequired).trim(),
	username: yup.string().required(defaultRequired).trim(),
	// phone: yup.string().matches(phoneRegExp, 'Phone number is not valid').required(defaultRequired),
	// email: yup.string().email().required(defaultRequired).trim(),
});

export const changePasswordResolver = yup.object().shape({
	password: yup.string().required("Password is required"),
	passwordConfirmation: yup
		.string()
		.oneOf([yup.ref("password"), null], "Passwords must match")
		.required(defaultRequired),
});
