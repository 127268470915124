import { UserDto } from "app/services/dto/user.dto";
import {
  useCreateUserMutation,
  useGetAdminsQuery,
} from "app/services/userServices";
import { entitySelector } from "app/utility/entitySelectors";
import React from "react";

export const useAdminProvider = () => {
  const { data, error, isFetching: isLoading, refetch } = useGetAdminsQuery();

  const [createUser] = useCreateUserMutation();

  const selectors = entitySelector<UserDto>(data);

  return React.useMemo(() => {
    return {
      data,
      error,
      isLoading,
      refetch,
      createUser,

      ...selectors,
    };
  }, [data, error, isLoading, refetch, createUser, selectors]);
};

export const AdminContext = React.createContext<ReturnType<
  typeof useAdminProvider
> | null>(null);

export const AdminProvider = ({ children }: { children: React.ReactNode }) => {
  const props = useAdminProvider();
  return (
    <AdminContext.Provider value={props}> {children} </AdminContext.Provider>
  );
};

export const useAdmin = () => {
  return React.useContext(AdminContext) as ReturnType<typeof useAdminProvider>;
};
