/* eslint-disable @typescript-eslint/ban-types */
import { Column } from "@material-table/core";
import DateRangePicker, { DateRange } from "@mui/lab/DateRangePicker";
import { TextField } from "@mui/material";
import { compareAsc, endOfDay, startOfDay } from "date-fns";
import React from "react";

export interface FieldDateRangeFilterProps<RowData extends object> {
  columnDef: Column<RowData> & { tableData?: { id: string } };
  onFilterChanged: (rowId: string, value: unknown) => void;
}

const FieldDateRangeFilter = <RowData extends object>({
  columnDef,
  onFilterChanged,
}: FieldDateRangeFilterProps<RowData>) => {
  const [value, setValue] = React.useState<DateRange<Date>>([null, null]);

  return (
    <DateRangePicker
      value={value}
      onChange={(newValue) => {
        setValue(newValue);
        const start = newValue[0] && startOfDay(newValue[0]);
        const end = newValue[1] && endOfDay(newValue[1]);
        const isValid = start && end && compareAsc(end, start);
        if (isValid && columnDef?.tableData?.id) {
          onFilterChanged(columnDef.tableData.id, { start, end });
        }
      }}
      startText='From'
      endText='To'
      renderInput={(startProps, endProps) => (
        <>
          <TextField {...startProps} sx={{ minWidth: 65 }} size='small' />
          {/* <Box sx={{ mx: 2 }}>to</Box> */}
          <TextField {...endProps} sx={{ minWidth: 65 }} size='small' />
        </>
      )}
    />
  );
};

export default FieldDateRangeFilter;
