import { Card, CardContent, CardHeader, Grid } from "@mui/material";
import { LatLngLiteral, LeafletMouseEvent, Map } from "leaflet";
import React from "react";
import { useHistory } from "react-router-dom";
import { BaseMap } from "..";
import { useAuth } from "../../../app/services/hooks/useAuth";
import { useAppDispatch, useAppSelector } from "../../../app/slices/generic";
import { clearMarkers, markerSelector } from "../../../app/slices/markerSlice";
import { entitySelector } from "../../../app/utility/entitySelectors";
import { SelectOptions } from "../../../config/types";
import { BookingCreationPanel } from "../components/BookingCreationPanel";
import { GeofenceLoader } from "../components/GeofenceLoader";
import { JobListPanel } from "../components/JobListPanel";
import { LocationSelector } from "../components/LocationSelector";
import { MarkerLoader } from "../components/MarkerLoader";
import { SearchControlLoader } from "../components/SearchControl";

export const CreateBookingPage = () => {
  const [map, setMap] = React.useState<Map | null>(null);

  const { role } = useAuth();
  const {
    latLng,
    setLatLng,
    setProject,
    project,
    handleLocation,
    handleProject,
    markers,
  } = useBookingCreation();

  const panel = React.useMemo(() => {
    return (
      <Grid
        component={BookingCreationPanel}
        latLng={latLng}
        project={project}
        map={map}
      />
    );
  }, [latLng, project]);

  const displayMap = React.useMemo(
    () => (
      <BaseMap whenCreated={setMap}>
        {/* <UnitTracker id={1} /> */}
        <GeofenceLoader handleProject={handleProject} />
        {/* <BookingLoader id={1} /> */}
        <MarkerLoader markers={markers} />
        <LocationSelector handleLocation={handleLocation} latLng={latLng} />
        <SearchControlLoader
          setLatLng={setLatLng}
          setProject={setProject}
          latLng={latLng}
        />
      </BaseMap>
    ),
    [
      latLng,
      setLatLng,
      setProject,
      project,
      handleLocation,
      handleProject,
      markers,
    ]
  );

  return (
    <Grid
      container
      sx={{ flex: 1, flexDirection: "row", height: "80vh" }}
      columnSpacing={2}
    >
      <Grid
        item
        md={8}
        container
        rowSpacing={2}
        direction='column'
        style={{
          display: "flex",
          flexDirection: "column",
          flex: "1 1 auto",
        }}
      >
        <Grid item sm>
          {displayMap}
        </Grid>
        <Grid item sm={3}>
          <Card style={{ height: "100%", maxHeight: "33vh", overflow: "auto" }}>
            <CardContent>
              <CardHeader title='Booking Orders' />
              {map ? <JobListPanel markers={markers} map={map} /> : null}
            </CardContent>
          </Card>
        </Grid>
      </Grid>
      {panel}
    </Grid>
  );
};

export const useBookingCreation = () => {
  const [eventHandled, setEventHandled] = React.useState(false);
  const [latLng, setLatLng] = React.useState<null | LatLngLiteral>(null);
  const [project, setProject] = React.useState<undefined | SelectOptions>();
  const markerEntity = useAppSelector(markerSelector);
  const { list: markers } = entitySelector(markerEntity);
  const dispatch = useAppDispatch();
  const history = useHistory();
  history.listen(() => {
    dispatch(clearMarkers());
  });

  const handleLocation = (e: LeafletMouseEvent) => {
    setLatLng({ lat: e.latlng.lat, lng: e.latlng.lng });
    if (!eventHandled) {
      setProject({ label: "Custom Request", value: "0" });
    }
    setEventHandled(false);
  };

  const handleProject = ({ name, id }: { name: string; id: number }) => {
    setEventHandled(true);
    setProject({ label: name, value: String(id) });
  };

  return React.useMemo(
    () => ({
      handleLocation,
      handleProject,
      setLatLng,
      latLng,
      project,
      setProject,
      eventHandled,
      setEventHandled,
      markers,
    }),
    [
      handleLocation,
      handleProject,
      setLatLng,
      latLng,
      project,
      setProject,
      eventHandled,
      setEventHandled,
      markers,
      clearMarkers,
    ]
  );
};
