import L from "leaflet";
import { Marker, Popup } from "react-leaflet";
import { MarkerProps } from "../../../app/slices/markerSlice";
import dropoffIcon from "./wh.png";
import pickupIcon from "./whb.png";

const PickupIcon = new L.Icon({
  iconUrl: pickupIcon,
  iconRetinaUrl: pickupIcon,
  iconSize: new L.Point(35, 45),
});

const DropoffIcon = new L.Icon({
  iconUrl: dropoffIcon,
  iconRetinaUrl: dropoffIcon,
  iconSize: new L.Point(35, 45),
});

export const MarkerLoader = ({ markers }: { markers?: MarkerProps[] }) => {
  return (
    <>
      {markers
        ? markers.map((item) => {
            if (item) {
              const { lat, lng, order } = item;
              return (
                lat &&
                lng && (
                  <Marker
                    position={{ lat, lng }}
                    key={String(order)}
                    icon={order == 0 ? PickupIcon : DropoffIcon}
                  >
                    <Popup>You are here</Popup>
                  </Marker>
                )
              );
            }
          })
        : null}
    </>
  );
};

export interface MarkerLoaderProps {
  id: number;
  list: MarkerProps[];
}
