import { Button, ButtonGroup, CardContent, Divider, Typography } from "@mui/material";
import { LatLngLiteral, Map } from "leaflet";
import React from "react";
import { FormProvider, useForm } from "react-hook-form";
import { GisRouteWialonDto } from "../../../app/services/dto/gis-route.dto";
import { useGetGisGeocodeQuery, useLazyGetGisGeocodeQuery } from "../../../app/services/routeServices";
import { ProjectLocationDto } from "../../../app/slices/dto/select-marker.dto";
import { useAppDispatch, useAppSelector } from "../../../app/slices/generic";
import { addMarker, markerSelector, OrderDto } from "../../../app/slices/markerSlice";
import { entitySelector } from "../../../app/utility/entitySelectors";
import { SelectOptions } from "../../../config/types";
import { CreateBookingModal } from "../../Booking/Modals/CreateBookingModal";
import { useBookingCreation } from "../../Map/page/CreateBooking";

export const OrderCreationForm = ({ latLng, project, map }: LocationDetailPanelProps) => {
	const { markers } = useBookingCreation();
	const initialOrderState = {
		pickup: { project: "", address: "" },
		dropoff: { project: "", address: "" },
	};
	const [orderState, setOrderState] = React.useState<OrderState>(initialOrderState);
	const methods = useForm();
	const dispatch = useAppDispatch();
	const markerEntity = useAppSelector(markerSelector);
	const { list } = entitySelector(markerEntity);
	const { data: geoAddress } = useGetGisGeocodeQuery(latLng);
	const [getGeoAddress, address] = useLazyGetGisGeocodeQuery();

	const handleGeoAddress = (latLng: LatLngLiteral) => {
		return getGeoAddress(latLng);
	};

	const handleSubmit = methods.handleSubmit<OrderDto>((props) => {
		const address = geoAddress ? geoAddress[0] : "";

		const params = {
			...props,
			...latLng,
			project: project?.label || "",
			address,
			order: list.length,
		};

		dispatch(addMarker(params));
		const newOrderState = Object.assign({}, initialOrderState);
		setOrderState(newOrderState);
	});

	const hasPickUp = Boolean(list && list.length >= 1);
	const hasRoute = Boolean(list && list.length >= 2);
	const orderType = hasPickUp ? "dropoff" : "pickup";

	React.useEffect(() => {
		if (project) {
			const newOrderState = Object.assign({}, { ...orderState });
			newOrderState[orderType].project = project?.label;
			setOrderState(newOrderState);
		}
	}, [project]);

	React.useEffect(() => {
		if (latLng) {
			handleGeoAddress(latLng);
		}
	}, [latLng]);

	React.useEffect(() => {
		if (address?.data) {
			const newOrderState = Object.assign({}, { ...orderState });

			if (address.data) {
				newOrderState[orderType].address = Array.isArray(address?.data) ? address.data[0] : address.data;
			}

			setOrderState(newOrderState);
		}
	}, [address]);

	const savedData = {
		pickup: list[0],
		dropoff: list[1],
	};

	return (
		<FormProvider {...methods}>
			<CardContent
				sx={{
					flexGrow: 1,
					height: "100%",
					display: "flex",
					flexDirection: "column",
				}}
			>
				{orderState && (
					<>
						<Typography variant="subtitle2" sx={{ fontWeight: 600 }}>
							{hasPickUp ? "Dropoff Address" : "Pickup Address"}
						</Typography>
						<Typography>{savedData[orderType]?.address || orderState[orderType].address}</Typography>
						<Typography variant="subtitle2" sx={{ fontWeight: 600 }}>
							{hasPickUp ? "Dropoff Project" : "Pickup Project"}
						</Typography>
						<Typography>{savedData[orderType]?.project || orderState[orderType].project}</Typography>
					</>
				)}
				<Divider style={{ padding: 5 }} />
			</CardContent>
			<ButtonGroup>
				<Button
					variant="contained"
					onClick={handleSubmit}
					disabled={hasRoute}
					sx={{ alignItems: "flex-end", marginTop: "auto", flex: 1 }}
				>
					Add Point
				</Button>
				{hasRoute && <CreateBookingModal />}
			</ButtonGroup>
		</FormProvider>
	);
};

export type LocationDetailPanelProps = {
	latLng: LatLngLiteral;
	map?: Map | null;
} & Partial<ProjectLocationDto>;

export interface LocationDetailsDto {
	latLng: LatLngLiteral;
	project?: SelectOptions | null;
	data?: GisRouteWialonDto;
}

export interface OrderState {
	pickup: OrderStateDetails;
	dropoff: OrderStateDetails;
}
export interface OrderStateDetails {
	address: string;
	project: string;
}
