import polyline from "@mapbox/polyline";
import { LatLngLiteral } from "leaflet";
import { Polyline } from "react-leaflet";
import { useGetGisRouteQuery } from "../../../app/services/routeServices";

export const PolylineLoader = ({ start, finish }: PolylineLoaderProps) => {
  const { data } = useGetGisRouteQuery({
    lat1: start.lat,
    lon1: start.lng,
    lat2: finish.lat,
    lon2: finish.lng,
  });

  if (data) {
    const positions = polyline.decode(data.points);
    return (
      <>
        <Polyline positions={positions} />
      </>
    );
  } else {
    return null;
  }
};

export interface PolylineLoaderProps {
  start: LatLngLiteral;
  finish: LatLngLiteral;
}
