import { LinearProgress } from "@mui/material";
import { createContext } from "react";
import { UserDto } from "../../../app/services/dto/user.dto";
import { useGetUserByIdQuery } from "../../../app/services/userServices";
import { SimpleProviderProps } from "../../types";

export const UserContext = createContext<Partial<UserDto>>({});

export const UserProvider = ({ id, children }: SimpleProviderProps) => {
  const { data, isFetching } = useGetUserByIdQuery(id as number);

  return isFetching ? (
    <LinearProgress />
  ) : data ? (
    <UserContext.Provider value={data}>{children}</UserContext.Provider>
  ) : (
    <LinearProgress />
  );
};
