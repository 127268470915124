import { Button, Grid } from "@mui/material";
import React from "react";
import { FormProvider, useForm } from "react-hook-form";
import FieldSelect from "shared/Input/Select";
import { useDriver } from "../../../app/services/hooks/useDriver";
import { BookingDriverReportTable } from "./BookingDriverReportTable";

export const BookingDriverReportProvider = () => {
  const [driver, setDriver] = React.useState<null | number>(null);
  const methods = useForm();
  const { selectOptions: drivers } = useDriver();

  const handleSubmit = methods.handleSubmit(({ driverId }) => {
    if (driverId) {
      setDriver(driverId);
    }
  });

  return (
    <>
      <FormProvider {...methods}>
        <Grid container spacing={1} alignItems='end'>
          <Grid item sx={{ minWidth: 200 }}>
            <FieldSelect
              name='driverId'
              options={drivers}
              label='Driver'
              rules={{ required: "This field is required" }}
              isClearable={true}
            />
          </Grid>
          <Grid item>
            <Button
              color='primary'
              onClick={handleSubmit}
              variant='contained'
              fullWidth
              sx={{ margin: 1 }}
            >
              Set Data
            </Button>
          </Grid>
        </Grid>
      </FormProvider>
      {driver ? <BookingDriverReportTable driverId={driver} /> : null}
    </>
  );
};
