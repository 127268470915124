import { UnitDto } from "app/services/dto/unit.dto";
import { useGetUnitsQuery } from "app/services/unitServices";
import { entitySelector } from "app/utility/entitySelectors";
import React from "react";
import { selectMapper } from "../../utility/selectMapper";

export const useUnit = () => {
  const { data, error, isFetching: isLoading, refetch } = useGetUnitsQuery(
    undefined,
    {
      pollingInterval: 5000,
      refetchOnMountOrArgChange: true,
    }
  );

  const selectors = entitySelector<UnitDto>(data);

  if (data) {
    selectors.selectOptions = selectMapper(data.entities, {
      label: ["name"],
      value: "id",
      type: "VEHICLE_TYPE",
    });
  }

  return React.useMemo(() => {
    return {
      data,
      error,
      isLoading,
      refetch,
      ...selectors,
    };
  }, [data, error, isLoading, refetch, selectors]);
};
