import { api, TAG_TYPES } from "app";
import { AuthDto, LoginDto } from "app/services/dto/user.dto";

const URL = "auth";

const authServices = api.injectEndpoints({
  endpoints: (build) => ({
    login: build.mutation<AuthDto, LoginDto>({
      query: (props) => ({
        url: `${URL}` + "/login",
        body: props,
        method: "POST",
      }),
      invalidatesTags: TAG_TYPES,
      transformResponse: (response: { data: AuthDto }) => {
        return response.data;
      },
    }),
    checkAccessToken: build.query<AuthDto, string>({
      query: (token) => ({
        url: `${URL}/me`,
        method: "GET",
        headers: {
          options: JSON.stringify({ rid: true }),
          authorization: `Bearer ${token}`,
        },
      }),
      providesTags: [{ type: "Auth" }],
      transformResponse: (response: { data: AuthDto }) => {
        return response.data;
      },
    }),
    getWialonToken: build.query<AuthDto, void>({
      query: () => ({
        url: `${URL}/login`,
        method: "GET",
        headers: {
          options: JSON.stringify({ rid: true }),
        },
      }),
      providesTags: [{ type: "Auth" }],
      transformResponse: (response: { data: AuthDto }) => {
        return response.data;
      },
    }),
  }),
  overrideExisting: false,
});

export default authServices;

export const {
  useLoginMutation,
  useLazyCheckAccessTokenQuery,
  useLazyGetWialonTokenQuery,
} = authServices;
