import React from "react";
import { TableProps } from "shared/Table";
import { CustomFilterComponent } from "shared/Table/CustomFilterComponent";
import { customDateFilter, customTextAreaFilter } from "shared/Table/customFilters";
import { BookingDto } from "../../../app/services/dto/booking.dto";
import { useBookedBooking } from "../../../app/services/hooks/useBookedBooking";
import { useCompletedBooking } from "../../../app/services/hooks/useCompletedBooking";
import { usePendingBooking } from "../../../app/services/hooks/usePendingBooking";

export const bookingTableProps = ({
	table,
	refetch,
	title,
	actions,
	role,
	columns,
}: BookingTableProps): TableProps<BookingDto> => {
	const baseActions = React.useMemo(() => {
		if (role && refetch) {
			const defaultActions = [
				{
					icon: "refresh",
					tooltip: "Refresh",
					isFreeAction: true,
					onClick: refetch,
				},
				// {
				//   icon: "content_copy",
				//   tooltip: "Copy to Clipboard",
				//   isFreeAction: true,
				//   onClick: refetch,
				// },
			];

			return actions ? [...defaultActions, ...actions] : defaultActions;
		} else {
			return [];
		}
	}, [role]);

	return {
		data: table as [],
		columns: columns || [
			{
				id: "id",
				title: "Booking ID",
				field: "id",
				customFilterAndSearch: customTextAreaFilter,
				defaultSort: "desc",
			},
			{
				id: "id",
				title: "Vehicle Type",
				field: "vehicleType",
			},
			{ id: "id", title: "Driver Name", field: "driver.name" },
			{
				id: "id",
				title: "Project",
				field: "routes[0].start.project",
			},
			{
				id: "id",
				title: "Status",
				field: "status",
				render: (rowData) => {
					const data = rowData.status;
					const isInProgress = rowData.status == "INPROGRESS";
					if (isInProgress) {
						const routes = rowData.routes[0] as any;
						const isFinishedPickup = routes.start.status == "COMPLETED";

						return isFinishedPickup ? `PICKUP ${routes.start.status}` : `DROPOFF ${routes.finish.status}`;
					}
					return data;
				},
				cellStyle: (rowData: string) => {
					return {
						color: rowData == "COMPLETED" ? "green" : rowData == "REJECTED" || rowData == "CANCELLED" ? "red" : "black",
					};
				},
			},
			{
				id: "id",
				title: "Pickup Date",
				field: "dateRequested",
				type: "datetime",
				customFilterAndSearch: customDateFilter,
				filterComponent: CustomFilterComponent,
			},
			{
				id: "id",
				title: "Dropoff Date",
				field: "routes[0].finish.timeRequested",
				type: "datetime",
				customFilterAndSearch: customDateFilter,
				filterComponent: CustomFilterComponent,
			},
			{
				id: "id",
				title: "Creation Date",
				field: "createdAt",
				type: "datetime",
			},
		],
		getData: refetch,
		actions: baseActions,
		title: title || "Details",
	};
};

export interface BookingTableProps extends Partial<TableProps<BookingDto>> {
	tableHook?: typeof useBookedBooking | typeof usePendingBooking | typeof useCompletedBooking;
	table?: (BookingDto | undefined)[];
	refetch?: () => void;
	title: string;
}
