import React from "react";
import { useHistory } from "react-router-dom";
import BaseTable from "shared/Table";
import { UserDto, WialonDriverDto } from "../../../app/services/dto/user.dto";
import { useAuth } from "../../../app/services/hooks/useAuth";
import { useDriver } from "../../../app/services/hooks/useDriver";
import { CreateUserModal } from "../../User/Modal/CreateUserModal";
import { EditUserModal } from "../../User/Modal/EditUserModal";
import { userTableProps } from "./tableProps";
import { SelectWialonDriverModal } from "features/User/Modal/SelectWialonDriverModal";
import { UserAutoFillProvider } from "features/User/Providers/UserAutoFillProvider";

export const DriverTable = () => {
	const history = useHistory();
	const [selectedWialonDriver, setSelectedWialonDriver] = React.useState<WialonDriverDto>({
		name: "",
		username: "",
	});
	const [selectedEdit, setSelectedEdit] = React.useState<null | number>(null);
	const [selectedCreate, setSelectedCreate] = React.useState<boolean>(false);
	const [selectedWialon, setSelectedWialon] = React.useState<boolean>(false);
	const { role } = useAuth();
	const props = userTableProps({
		tableHook: useDriver,
		title: "Drivers",
		role,
		actions: [
			{
				icon: "edit",
				tooltip: "Edit User",
				onClick: (_e, rowData) => {
					const isRowData = rowData as UserDto;
					if (isRowData?.id) {
						setSelectedEdit(isRowData.id);
					}
				},
			},
			{
				icon: "add",
				tooltip: "Add Driver",
				isFreeAction: true,
				onClick: () => {
					setSelectedWialon(true);
				},
			},
		],
	});

	return (
		<>
			<BaseTable {...props} />
			<SelectWialonDriverModal
				selected={selectedWialon}
				setSelected={setSelectedWialon}
				setSelectedWialonDriver={setSelectedWialonDriver}
				setSelectedCreate={setSelectedCreate}
			/>
			<EditUserModal selected={selectedEdit} setSelected={setSelectedEdit} type="DRIVER" />
			<UserAutoFillProvider data={selectedWialonDriver}>
				<CreateUserModal selected={selectedCreate} setSelected={setSelectedCreate} type="DRIVER" />
			</UserAutoFillProvider>
		</>
	);
};

export default DriverTable;
