import styled from "@emotion/styled";
import { Grid, Paper, Theme } from "@mui/material";
import { theme } from "config/theme";
import LoginFields from "features/User/Fields/Login";
import FormGrid from "shared/Form/Grid";

export const LoginScreen = () => {
  const fields = [{ component: LoginFields }];

  return (
    <LoginGrid container>
      <BackgroundGrid item xs={false} sm={4} md={8} theme={theme} />
      <Grid
        item
        xs={12}
        sm={8}
        md={4}
        component={Paper}
        elevation={6}
        square
        sx={{
          alignItems: "center",
          flex: 1,
          flexDirection: "row",
          justifyItems: "center",
          display: "flex",
        }}
      >
        <FormGrid title='Sign In' fields={fields} />
      </Grid>
    </LoginGrid>
  );
};

export default LoginScreen;

interface BackgroundGridProps {
  theme: Theme;
}

const LoginGrid = styled(Grid)({
  display: "flex",
  height: "100vh",
});

const BackgroundGrid = styled(Grid)(({ theme }: BackgroundGridProps) => ({
  backgroundImage: "url(/img/LOGO.png)",
  backgroundSize: "fit",
  backgroundRepeat: "no-repeat",
  backgroundPosition: "center center",
  backgroundColor: "#fff",
  objectFit: "fill",
}));
