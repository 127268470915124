import useIsMounted from "app/hooks/useIsMounted";
import React from "react";
import { useFormContext } from "react-hook-form";
import CheckBox from "shared/Input/CheckBox";
import FieldText from "shared/Input/Text";

export const LoginFields = () => {
  const isMounted = useIsMounted();
  const { setValue } = useFormContext();

  const [rememberMe, setRememberMe] = React.useState(
    localStorage.getItem("rememberMe") === "true"
  );

  const handleRememberMe = (
    _event: React.ChangeEvent<HTMLInputElement>,
    checked: boolean
  ) => {
    localStorage.setItem("rememberMe", String(checked));
    setRememberMe(checked);
  };

  React.useEffect(() => {
    const setRememberedCredentials = async () => {
      if (isMounted() && rememberMe) {
        [
          {
            name: "username",
            value: localStorage.getItem("rememberMeUsername") || "",
          },
          {
            name: "password",
            value: localStorage.getItem("rememberMePassword") || "",
          },
        ].forEach(({ name, value }) => {
          setValue(name, value, { shouldDirty: true });
        });
      }
    };

    setRememberedCredentials();
  }, []);

  return (
    <>
      <FieldText
        name='username'
        label='username'
        autoComplete='ats-username'
        rules={{
          required: "This field is required",
          minLength: { value: 1, message: "Minimun Length is 1" },
        }}
      />
      <FieldText
        name='password'
        label='password'
        autoComplete='ats-password'
        type='password'
        rules={{ required: "This field is required" }}
      />
      {
        <CheckBox
          color='primary'
          onChange={handleRememberMe}
          checked={rememberMe}
        />
      }
    </>
  );
};

export default LoginFields;
