import { createEntityAdapter, EntityState } from "@reduxjs/toolkit";
import { api, TAG_TYPES } from "app";
import { BookingDto } from "./dto/booking.dto";

const URL = "bookings";

const bookingServices = api.injectEndpoints({
  endpoints: (build) => ({
    getBookingById: build.query<BookingDto, number>({
      query: (id) => ({
        url: `${URL}/${id}`, method: "GET", headers: {
          options: JSON.stringify({ rid: true })
        }
      }),
      providesTags: TAG_TYPES,
    }),
    updateBookingById: build.mutation<BookingDto, Partial<BookingDto>>({
      query: (params) => ({
        url: `${URL}/${params.id}`, method: "PATCH", headers: {
          options: JSON.stringify({ rid: true })
        }, body: params
      }),
      invalidatesTags: TAG_TYPES,
    }),

    getPendingBookings: build.query<EntityState<BookingDto>, void>({
      query: () => ({
        url: `${URL}/pending`, method: "GET", headers: {
          options: JSON.stringify({ rid: true })
        }
      }),
      providesTags: TAG_TYPES,
      transformResponse: (response: { data: BookingDto[] }) => {
        return bookingAdapter.addMany(
          bookingAdapter.getInitialState(),
          response.data
        )
      }
    }),
    getBookedBookings: build.query<EntityState<BookingDto>, void>({
      query: () => ({
        url: `${URL}/booked`, method: "GET", headers: {
          options: JSON.stringify({ rid: true })
        }
      }),
      providesTags: TAG_TYPES,
      transformResponse: (response: { data: BookingDto[] }) => {
        return bookingAdapter.addMany(
          bookingAdapter.getInitialState(),
          response.data
        )
      }
    }),
    getCompletedBookings: build.query<EntityState<BookingDto>, void>({
      query: () => ({
        url: `${URL}/completed`, method: "GET", headers: {
          options: JSON.stringify({ rid: true })
        }
      }),
      providesTags: TAG_TYPES,
      transformResponse: (response: { data: BookingDto[] }) => {
        return bookingAdapter.addMany(
          bookingAdapter.getInitialState(),
          response.data
        )
      }
    }),
    createBooking: build.mutation<BookingDto, Record<string, unknown>>({
      query: (data) => ({
        url: `${URL}`, method: "POST", headers: {
          options: JSON.stringify({ rid: true })
        }, body: data
      }),
      invalidatesTags: TAG_TYPES,
    }),
    getBookingCount: build.query<{ count: number, status: string }[], void>({
      query: () => ({
        url: `${URL}/count`, method: "GET", headers: {
          options: JSON.stringify({ rid: true })
        }
      }),
      providesTags: TAG_TYPES,
    }),
    getBookingReport: build.query<EntityState<BookingDto>, void>({
      query: () => ({
        url: `${URL}/reports/general`, method: "GET", headers: {
          options: JSON.stringify({ rid: true })
        }
      }),
      providesTags: TAG_TYPES,
      transformResponse: (response: { data: BookingDto[] }) => {
        return bookingAdapter.addMany(
          bookingAdapter.getInitialState(),
          response.data
        )
      }
    }),
    getBookingDriverReport: build.query<EntityState<BookingDto>, number>({
      query: (id) => ({
        url: `${URL}/reports/driver/${id}`, method: "GET", headers: {
          options: JSON.stringify({ rid: true })
        }
      }),
      providesTags: TAG_TYPES,
      transformResponse: (response: { data: BookingDto[] }) => {
        return bookingAdapter.addMany(
          bookingAdapter.getInitialState(),
          response.data
        )
      }
    }),
  }),
  overrideExisting: false,
});

export default bookingServices;

export const {
  useGetBookingByIdQuery,
  useGetBookedBookingsQuery,
  useGetCompletedBookingsQuery,
  useGetPendingBookingsQuery,
  useCreateBookingMutation,
  useGetBookingCountQuery,
  useUpdateBookingByIdMutation,
  useGetBookingReportQuery,
  useGetBookingDriverReportQuery
} = bookingServices;



const bookingAdapter = createEntityAdapter<BookingDto>({});