import BrokenImageIcon from "@mui/icons-material/BrokenImage";
import ImageIcon from "@mui/icons-material/Image";
import { Button, CardMedia, Dialog, DialogContent } from "@mui/material";
import React from "react";

export type ImageViewModalProps = {
  url?: string;
  title: string;
};

export const ImageViewModal = ({ url, title }: ImageViewModalProps) => {
  const [open, setOpen] = React.useState<boolean>(false);
  const handleClose = React.useCallback(() => setOpen(false), [setOpen]);

  return (
    <>
      <Button
        variant='outlined'
        startIcon={url ? <ImageIcon /> : <BrokenImageIcon />}
        disabled={!url}
        sx={{ height: "100%" }}
        onClick={() => setOpen(true)}
      >
        {title}
      </Button>
      <Dialog
        open={open}
        onClose={handleClose}
        fullWidth
        maxWidth={"lg"}
        sx={{ paddingX: 5 }}
      >
        <DialogContent sx={{ paddingX: 5 }}>
          <CardMedia
            image={url || ""}
            title='Advance Telematic Solutions LLC'
            sx={{
              height: "auto",
              width: "100%",
              paddingTop: "56.25%",
              objectFit: "cover",
            }}
          />
        </DialogContent>
      </Dialog>
    </>
  );
};
