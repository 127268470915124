import { Grid } from "@mui/material";
import { Map } from "leaflet";
import React from "react";
import { useParams } from "react-router-dom";
import { BaseMap } from "..";
import { useAuth } from "../../../app/services/hooks/useAuth";
import { BookingLoader } from "../components/BookingLoader";
import { BookingReviewPanel } from "../components/BookingReviewPanel";

export const ViewBookingPage = () => {
  const { id } = useParams<{ id: string }>();
  const [map, setMap] = React.useState<Map | null>(null);
  const { role } = useAuth();

  const displayMap = React.useMemo(
    () => (
      <BaseMap whenCreated={setMap}>
        <BookingLoader id={id} />
      </BaseMap>
    ),
    []
  );

  return (
    <Grid
      container
      sx={{ flex: 1, flexDirection: "row", height: "80vh" }}
      columnSpacing={2}
    >
      <Grid
        item
        md={8}
        container
        rowSpacing={2}
        direction='column'
        style={{
          display: "flex",
          flexDirection: "column",
          flex: "1 1 auto",
        }}
      >
        <Grid item sm>
          {displayMap}
        </Grid>
      </Grid>
      <Grid component={BookingReviewPanel} id={id} map={map} />
    </Grid>
  );
};
