import React from "react";
import { useHistory } from "react-router-dom";
import BaseTable from "shared/Table";
import { useGetCompletedBookingsQuery } from "../../../app/services/bookingServices";
import { BookingDto } from "../../../app/services/dto/booking.dto";
import { useAuth } from "../../../app/services/hooks/useAuth";
import { entitySelector } from "../../../app/utility/entitySelectors";
import { bookingTableProps } from "./tableProps";

export const SummaryBookingsTable = () => {
  const {
    refetch,
    table,
    isFetching: isLoading,
  } = useGetCompletedBookingsQuery(undefined, {
    selectFromResult: ({ data, ...params }) => ({
      ...entitySelector(data),
      ...params,
    }),
  });
  const history = useHistory();
  // const [selectedView, setSelectedView] = React.useState<null | number>(null);
  const { role } = useAuth();

  const completedTableProps = bookingTableProps({
    refetch,
    table: table.filter((item) => item?.status == "COMPLETED"),
    isLoading,
    title: "Completed Trip Summary",
    actions: ["ADMIN", "SUPERUSER"].includes(role)
      ? [
          // {
          //   icon: "pageview",
          //   tooltip: "View Trip",
          //   onClick: (_e, rowData) => {
          //     const isRowData = rowData as BookingDto;
          //     if (isRowData?.id) {
          //       setSelectedView(isRowData.id);
          //     }
          //   },
          // },
          {
            icon: "pageview",
            tooltip: "View Trip",
            onClick: (_e, rowData) => {
              history.push("/bookings/summary/" + (rowData as BookingDto).id);
            },
          },
        ]
      : [],
    role,
  });

  return (
    <>
      {/* <ViewBookingSummaryModal
        selected={selectedView}
        setSelected={setSelectedView}
      /> */}
      <BaseTable {...completedTableProps} />
    </>
  );
};
