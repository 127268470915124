import { Grid } from "@mui/material";
import { isSameDay } from "date-fns";
import { orderBy } from "lodash";
import React from "react";
import { useFormContext } from "react-hook-form";
import FieldSelect from "shared/Input/Select";
import { useBookedBooking } from "../../../app/services/hooks/useBookedBooking";
import { useDriver } from "../../../app/services/hooks/useDriver";
import { useUnit } from "../../../app/services/hooks/useUnit";
import { SelectOptions } from "../../../config/types";
import { BookingContext } from "../../Booking/Providers/BookingProvider";

export const UnitDriverFields = () => {
  const {
    availableVehicles,
    availableDrivers,
    setDriver,
  } = useAvailableDrivers();
  return (
    <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
      <Grid item sm={6}>
        <FieldSelect
          name='driver'
          options={orderBy(availableDrivers, ["color"], ["desc"])}
          label='Driver'
          rules={{ required: "This field is required" }}
          customSelectHook={setDriver}
          isClearable={true}
        />
      </Grid>
      <Grid item sm={6}>
        <FieldSelect
          name='vehicle'
          options={availableVehicles}
          label='Vehicle'
          rules={{ required: "This field is required" }}
          // customSelectHook={setVehicle}
          isClearable={true}
        />
      </Grid>
    </Grid>
  );
};

export interface UnitDriverFieldsProps {
  setDriver?: (param: number) => void;
  setBooking?: (param: number) => void;
  setVehicle?: (param: number) => void;
  driverOptions: any[];
  vehicleOptions: any[];
}

const useAvailableDrivers = () => {
  const [driver, setDriver] = React.useState<{
    value: string;
    assignedVehicles: string;
  } | null>(null);
  const { dateRequested } = React.useContext(BookingContext);
  const { setValue } = useFormContext();
  const { list: bookings } = useBookedBooking();
  const { selectOptions: drivers } = useDriver();
  const unavailableDrivers = bookings
    ? bookings
      .filter(
        (item) =>
          dateRequested &&
          isSameDay(new Date(item.dateRequested), new Date(dateRequested))
      )
      .reduce<number[]>((acc, item) => {
        const driverItem = item?.driver;
        if (driverItem) {
          acc.push(
            typeof driverItem === "object" ? driverItem?.id : driverItem
          );
        }
        return acc;
      }, [])
    : [];

  // const availableDrivers = drivers
  //   ? drivers.filter((item) => {
  //       const isUnavailable = unavailableDrivers.includes(Number(item.value));
  //       const isAvailable = !isUnavailable;
  //       return isAvailable;
  //     })
  //   : [];

  const availableDrivers = drivers
    ? drivers.reduce<SelectOptions[]>((acc, item) => {
      const isUnavailable = unavailableDrivers.includes(Number(item.value));
      acc.push({
        ...item,
        color: isUnavailable ? "red" : "green",
      });
      return acc;
    }, [])
    : [];

  const { selectOptions: vehicles } = useUnit();
  const availableVehicles = (vehicles || []).filter((item) =>
    ["PICKUP-1TON",
      "PICKUP-2-4TON",
      "PICKUP-7-10TON",
      "TRAILER",].includes(item.type)
  );

  const handleClear = (e: any) => {
    setValue("vehicle", null);
    setDriver(e);
  };
  React.useEffect(() => setValue("vehicle", driver?.assignedVehicles || null), [
    driver,
  ]);

  return {
    availableDrivers,
    availableVehicles,
    setDriver: handleClear,
  };
};
