import LocationSearchingIcon from "@mui/icons-material/LocationSearching";
import {
  Timeline,
  TimelineConnector,
  TimelineContent,
  TimelineDot,
  TimelineItem,
  TimelineOppositeContent,
  TimelineSeparator,
} from "@mui/lab";
import {
  Avatar,
  Button,
  CardHeader,
  Chip,
  Grid,
  IconButton,
  Typography,
} from "@mui/material";
import { LatLngLiteral, Map } from "leaflet";
import React from "react";
import { BookingDto } from "../../../app/services/dto/booking.dto";
import { GisRouteWialonDto } from "../../../app/services/dto/gis-route.dto";
import { useAuth } from "../../../app/services/hooks/useAuth";
import { useGetRouteByIdQuery } from "../../../app/services/routeServices";
import { ProjectLocationDto } from "../../../app/slices/dto/select-marker.dto";
import { SelectOptions } from "../../../config/types";
import { EditBookingModal } from "../../Booking/Modals/EditBookingModal";
import { LocationDetailPanelViewer } from "./LocationDetailPanelViewer";

export const JobListReview = ({
  ids,
  map,
  booking,
}: {
  ids: (string | number)[];
  map: Map;
  booking?: BookingDto;
}) => {
  const { role } = useAuth();
  const [selected, setSelected] = React.useState<null | number>(null);
  const [select, setSelect] = React.useState<"finish" | "start">("start");
  const { data } = useGetRouteByIdQuery(ids[0]);
  const markers = data
    ? [
        { ...data.start, st: "start" },
        { ...data.finish, st: "finish" },
      ]
    : [];
  // a little function to help us with reordering the result

  const gotoLocation = ({ st, ...latlng }: LatLngLiteral & { st: string }) => {
    map.setView(latlng, 15, {
      animate: true,
      duration: 1,
    });
    setSelect(st as "finish" | "start");
  };

  const isBooked =
    role == "SUPERUSER" && ["ACCEPTED"].includes(booking?.status || "");

  return (
    <>
      {data && data[select]?.id && (
        <LocationDetailPanelViewer id={data[select]?.id} booking={booking} />
      )}
      <Timeline position='right' sx={{ marginTop: "auto", flex: 1 }}>
        {markers.map((item, index) => {
          // const dateRequested =
          //   String(((item as unknown) as JobDto)?.timeRequested) ||
          //   item.dateRequested ||
          //   null;

          // const formattedDateRequested = dateRequested
          //   ? isNumber(dateRequested)
          //     ? format(fromUnixTime(dateRequested), "yyyy-MM-dd hh:mm")
          //     : String(format(new Date(dateRequested), "MMM dd, yyyy hh:mm a"))
          //   : null;

          return item ? (
            <TimelineItem key={item?.project + "-" + index}>
              <TimelineOppositeContent
                color='text.secondary'
                style={{
                  maxWidth: "5px",
                }}
              ></TimelineOppositeContent>
              <TimelineSeparator>
                <TimelineDot />
                <TimelineConnector />
              </TimelineSeparator>
              <TimelineContent>
                {/* <Typography variant='subtitle2' style={{ fontWeight: 300 }}>
                  {formattedDateRequested}
                </Typography> */}
                <CardHeader
                  avatar={
                    <IconButton
                      edge='end'
                      aria-label='delete'
                      onClick={() =>
                        gotoLocation({
                          st: item.st,
                          lat: item.lat,
                          lng: item.lng,
                        })
                      }
                    >
                      <Avatar>
                        <LocationSearchingIcon />
                      </Avatar>
                    </IconButton>
                  }
                  title={
                    <Grid>
                      <Typography variant='subtitle2'>
                        {item?.project}
                      </Typography>
                      <Grid>
                        <Chip
                          label={item?.action}
                          color={item?.action == "PICKUP" ? "success" : "error"}
                        />
                      </Grid>
                    </Grid>
                  }
                  subheader={item?.address}
                  sx={{ color: item?.action == "PICKUP" ? "green" : "red" }}
                />
              </TimelineContent>
            </TimelineItem>
          ) : null;
        })}
      </Timeline>
      {(["PENDING"].includes(booking?.status || "") || isBooked) && (
        <>
          <Button
            onClick={() => setSelected(booking?.id || null)}
            variant='contained'
          >
            Select Driver and Vehicle
          </Button>
          <EditBookingModal selected={selected} setSelected={setSelected} />
        </>
      )}
    </>
  );
};

export type LocationDetailPanelProps = {
  latLng: LatLngLiteral;
} & Partial<ProjectLocationDto>;

export interface LocationDetailsDto {
  latLng: LatLngLiteral;
  project?: SelectOptions | null;
  data?: GisRouteWialonDto;
}
