import MaterialTable, {
  Action,
  MaterialTableProps,
  MTableBodyRow,
  MTableCell,
} from "@material-table/core";
import { ExportCsv } from "@material-table/exporters";
import { theme } from "config/theme";
import { omit } from "lodash";
import { TABLE_ICONS } from "./Icons";

// eslint-disable-next-line @typescript-eslint/ban-types
export interface TableProps<RowData extends object>
  extends MaterialTableProps<RowData> {
  touchMe?: string;
  getData?: () => void;
  role?: string;
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const BaseTable = <RowData extends Record<string, any>>(
  props: TableProps<RowData>
) => {
  return (
    <MaterialTable
      icons={TABLE_ICONS}
      options={{
        filtering: true,
        grouping: false,
        pageSize: 10,
        pageSizeOptions: [10, 20, 50, 100],
        columnsButton: false,
        tableLayout: "auto",
        exportAllData: true,
        exportMenu: [
          {
            label: "Export to Excel",
            exportFunc: (col, data) => {
              ExportCsv(
                col,
                data,
                (props?.title || "Report") + "-" + new Date()
              );
            },
          },
        ],
        headerStyle: {
          whiteSpace: "nowrap",
        },
        rowStyle: (data: any, index: number, level: number) => ({
          backgroundColor: index % 2 == 0 ? theme.palette.grey[50] : undefined,
        }),
        actionsCellStyle: {
          borderRight: "2px solid #e5e5e5",
        },
        columnResizable: true,
      }}
      components={{
        Row: (props) => <MTableBodyRow id={props.data.id} {...props} />,
        Cell: (props) => (
          <MTableCell
            {...props}
            style={{
              overflow: "overlay",
              whiteSpace: "nowrap",
              maxWidth: 100,
              "&::WebkitScrollbarThumb": "none!important" as any,
            }}
          />
        ),
        ...props.components,
      }}
      {...omit(props, "classes")}
      columns={
        props.columns.map((item) => ({
          ...item,
        })) || []
      }
    />
  );
};

export default BaseTable;

// eslint-disable-next-line @typescript-eslint/ban-types
export type TableActions<RowData extends object> = (
  | Action<RowData>
  | ((rowData: RowData) => Action<RowData>)
  | { action: (rowData: RowData) => Action<RowData>; position: string }
)[];
