import { yupResolver } from "@hookform/resolvers/yup";
import { CardContent, Grid, TextField } from "@mui/material";
import { FormProvider, useForm } from "react-hook-form";
import { createOrderResolver } from "../../../app/resolvers/create-order.resolver";
import { BookingDto } from "../../../app/services/dto/booking.dto";
import { useGetJobByIdQuery } from "../../../app/services/jobServices";
import { useGetUnitByIdQuery } from "../../../app/services/unitServices";
import { useGetUserByIdQuery } from "../../../app/services/userServices";

export const LocationDetailPanelViewer = ({
  id,
  booking,
}: LocationDetailPanelProps) => {
  const { data } = useGetJobByIdQuery(id);
  const { data: vehicle } = useGetUnitByIdQuery(booking?.vehicle || 0);
  const { data: user } = useGetUserByIdQuery(
    String(booking?.user as number) || ""
  );
  const { data: driver } = useGetUserByIdQuery(
    String(booking?.driver as number) || ""
  );

  const methods = useForm({
    resolver: yupResolver(createOrderResolver),
  });

  return (
    <FormProvider {...methods}>
      {booking && data && (
        <>
          <Grid item container component={CardContent}>
            <Grid item lg={6}>
              <TextField
                label='Requestor Name'
                variant='filled'
                fullWidth
                value={user?.name || ""}
                InputProps={{
                  readOnly: true,
                  disableUnderline: true,
                }}
                InputLabelProps={{
                  shrink: true,
                  sx: {
                    fontWeight: 600,
                  },
                }}
              />
            </Grid>
            <Grid item lg={6}>
              <TextField
                label='Requestor Contact No.'
                variant='filled'
                fullWidth
                value={user?.phone || ""}
                InputProps={{
                  readOnly: true,
                  disableUnderline: true,
                }}
                InputLabelProps={{
                  shrink: true,
                  sx: {
                    fontWeight: 600,
                  },
                }}
              />
            </Grid>

            <Grid item lg={6}>
              <TextField
                label='Charge to Project'
                variant='filled'
                fullWidth
                value={booking.chargeProject || ""}
                InputProps={{
                  readOnly: true,
                  disableUnderline: true,
                }}
                InputLabelProps={{
                  shrink: true,
                  sx: {
                    fontWeight: 600,
                  },
                }}
              />
            </Grid>

            <Grid item lg={6}>
              <TextField
                label='Supplier Number'
                variant='filled'
                fullWidth
                value={booking.supplierPn || ""}
                InputProps={{
                  readOnly: true,
                  disableUnderline: true,
                }}
                InputLabelProps={{
                  shrink: true,
                  sx: {
                    fontWeight: 600,
                  },
                }}
              />
            </Grid>

            <Grid item lg={6}>
              <TextField
                label='Project'
                variant='filled'
                fullWidth
                value={data?.project || ""}
                InputProps={{
                  readOnly: true,
                  disableUnderline: true,
                }}
                InputLabelProps={{
                  shrink: true,
                  sx: {
                    fontWeight: 600,
                  },
                }}
              />
            </Grid>
            <Grid item lg={6}>
              <TextField
                label='Address'
                variant='filled'
                fullWidth
                value={data?.address || ""}
                InputProps={{
                  readOnly: true,
                  disableUnderline: true,
                }}
                InputLabelProps={{
                  shrink: true,
                  sx: {
                    fontWeight: 600,
                  },
                }}
              />
            </Grid>

            {driver && vehicle && (
              <>
                <Grid item lg={6}>
                  <TextField
                    label='Vehicle'
                    variant='filled'
                    fullWidth
                    value={vehicle.name || booking.vehicle || ""}
                    InputProps={{
                      readOnly: true,
                      disableUnderline: true,
                    }}
                    InputLabelProps={{
                      shrink: true,
                      sx: {
                        fontWeight: 600,
                      },
                    }}
                  />
                  <TextField
                    label='Vehicle Type'
                    variant='filled'
                    fullWidth
                    value={booking.vehicleType || ""}
                    InputProps={{
                      readOnly: true,
                      disableUnderline: true,
                    }}
                    InputLabelProps={{
                      shrink: true,
                      sx: {
                        fontWeight: 600,
                      },
                    }}
                  />
                </Grid>

                <Grid item lg={6}>
                  <TextField
                    label='Driver'
                    variant='filled'
                    fullWidth
                    value={driver.name || booking.driver || ""}
                    InputProps={{
                      readOnly: true,
                      disableUnderline: true,
                    }}
                    InputLabelProps={{
                      shrink: true,
                      sx: {
                        fontWeight: 600,
                      },
                    }}
                  />
                  <TextField
                    label='Driver Type'
                    variant='filled'
                    fullWidth
                    value={driver.phone || ""}
                    InputProps={{
                      readOnly: true,
                      disableUnderline: true,
                    }}
                    InputLabelProps={{
                      shrink: true,
                      sx: {
                        fontWeight: 600,
                      },
                    }}
                  />
                </Grid>
              </>
            )}
            <Grid item lg={12}>
              <TextField
                label='Description'
                variant='filled'
                fullWidth
                value={data?.description || ""}
                InputProps={{
                  readOnly: true,
                  disableUnderline: true,
                }}
                InputLabelProps={{
                  shrink: true,
                  sx: {
                    fontWeight: 600,
                  },
                }}
              />
            </Grid>
          </Grid>
        </>
      )}
    </FormProvider>
  );
};

export type LocationDetailPanelProps = {
  id: string | number;
  booking?: BookingDto;
};
