/* eslint-disable @typescript-eslint/no-explicit-any */
import { SelectOptions } from "config/types";

export function selectMapper<Entity extends Record<string, any> | string[]>(
  entity: Entity,
  options: SelectMapperOptions
): SelectOptions[];
export function selectMapper<Entity extends string[]>(
  entity: Entity,
  options?: SelectMapperOptions
): SelectOptions[];
export function selectMapper<Entity extends Record<string, any> | string[]>(
  entity: Entity,
  options?: SelectMapperOptions
): SelectOptions[] {
  return (
    entity &&
    Object.values(entity || []).reduce<SelectOptions[]>((acc, item) => {
      if (item && typeof item === "string") {
        acc.push({ label: item, value: item });
      } else if (options) {
        const keys = Object.keys(options);
        const props = keys.reduce<Partial<SelectOptions>>((prop, key) => {
          if (Array.isArray(options.key)) {
            const optionValue = (options[key] as unknown) as string[];
            const itemValue = optionValue
              .map((term) => item[term] || item.id)
              .join("-");
            if (itemValue) {
              prop[key] = itemValue;
            }
            return prop;
          } else {
            const optionValue = options[key] as string;
            const itemValue = item[optionValue] || item.id;
            if (itemValue) {
              prop[key] = itemValue;
            }
            return prop;
          }
        }, {});

        const validProp = ["value", "label"].every((term) =>
          Object.keys(props).includes(term)
        );
        if (validProp) {
          acc.push(props as SelectOptions);
        }
      }

      // if (props)

      //     if (typeof item === "string") {
      //         acc.push({ label: item, value: item })
      //     } else if (options) {
      //         Object.keys(options).map((key) => [key, options[key]]);

      //         const value: string = options.value
      //             ? item[options.value]
      //             : item.id
      //         const label: string = options?.label
      //             ? typeof options?.label === "string"
      //                 ? item[options.label] || item[options.value]
      //                 : options.label(item)
      //             : item.name || item[key[0]]
      //         if (value && label) {
      //             acc.push({ label, value })
      //         }
      //     }
      return acc;
    }, [])
  );
}

interface SelectMapperOptions {
  label: string | ((item: string) => string) | string[];
  value: string;
  [key: string]: string | ((item: string) => string) | string[];
}
