import {
  ActionReducerMapBuilder,
  createSlice,
  isFulfilled,
  isPending,
  isRejected,
  PayloadAction,
  SliceCaseReducers,
  unwrapResult,
  ValidateSliceCaseReducers
} from "@reduxjs/toolkit";
import type { AppDispatch, RootState } from "app/store";
import React from "react";
import { TypedUseSelectorHook, useDispatch, useSelector } from "react-redux";

export const useAppDispatch = () => useDispatch<AppDispatch>();
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;
export const useUnwrapAsyncThunk = () => {
  const dispatch = useDispatch();
  return React.useCallback(
    (asyncThunk) => {
      return dispatch(asyncThunk).then(unwrapResult);
    },
    [dispatch]
  );
};

export interface GenericState<Entity> {
  ids: number[];
  entities: Record<string, Entity>;
  selected: number | null;
  multipleSelected?: number[];
  status: "pending" | "finished" | "error" | "idle";
  url?: string;
}



export const createGenericSlice = <
  Entity,
  Reducers extends SliceCaseReducers<GenericState<Entity>>
>({
  name = "",
  initialState,
  reducers,
  extraReducers,
}: {
  name: string;
  initialState: GenericState<Entity>;
  reducers: ValidateSliceCaseReducers<GenericState<Entity>, Reducers>;
  extraReducers: (
    builder: ActionReducerMapBuilder<GenericState<Entity>>
  ) => ActionReducerMapBuilder<GenericState<Entity>> | void;
}) => {
  return createSlice({
    name,
    initialState,
    reducers: {
      select(state, action: PayloadAction<number>) {
        state.selected = action.payload;
      },
      unselect(state) {
        state.selected = null;
      },
      start(state) {
        state.status = "pending";
      },
      success(state: GenericState<Entity>) {
        state.status = "finished";
      },

      ...reducers,
    },
    extraReducers: (builder) => {
      if (extraReducers) {
        builder = extraReducers(builder) as ActionReducerMapBuilder<
          GenericState<Entity>
        >;
      }

      return builder
        ?.addMatcher(isPending, (state) => {
          state.status = "pending";
        })
        .addMatcher(isRejected, (state) => {
          state.status = "error";
        })
        .addMatcher(isFulfilled, (state) => {
          state.status = "finished";
        });
    },
  });
};

// export const mapToEntity = <Entity extends ResponsePayload>(data: Entity[]) => {
//     return data.reduce((acc: { ids: number[], entities: Entity[] | Record<string | number, unknown> }, item) => {
//         const itemId = item.id
//         if (itemId) {
//             acc.ids.push(itemId)
//             acc.entities[itemId] = item
//         }
//         return acc
//     }, { ids: [], entities: {} })
// }
