import { Grid } from "@mui/material";
import React from "react";
import FieldText from "shared/Input/Text";
import { UserContext } from "../Providers/UserProvider";
import { UserAutoFillContext } from "../Providers/UserAutoFillProvider";

export const EditUserFields = ({ type }: { type: "ADMIN" | "DRIVER" | "CLIENT" }) => {
	const { username, name, email, phone } = React.useContext(UserContext);
	const { username: autoFillUsername, name: AutoFillName } = React.useContext(UserAutoFillContext);
	return (
		<Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
			<Grid item sm={6}>
				<FieldText
					name="username"
					label="Username"
					rules={{ required: type === "DRIVER" ? false : "This field is required" }}
					defaultValue={username || autoFillUsername || ""}
				/>
			</Grid>
			<Grid item sm={6}>
				<FieldText
					name="email"
					label="Email"
					rules={{ required: "This field is required" }}
					defaultValue={email || ""}
				/>
			</Grid>
			<Grid item sm={6}>
				<FieldText
					name="name"
					label="Name"
					rules={{ required: type === "DRIVER" ? false : "This field is required" }}
					defaultValue={name || AutoFillName || ""}
				/>
			</Grid>
			<Grid item sm={6}>
				<FieldText
					name="phone"
					label="Phone"
					rules={{ required: "This field is required" }}
					defaultValue={phone || ""}
				/>
			</Grid>
		</Grid>
	);
};

export interface UnitDriverFieldsProps {
	setDriver?: (param: number) => void;
	setBooking?: (param: number) => void;
	setVehicle?: (param: number) => void;
	driverOptions: any[];
	vehicleOptions: any[];
}
