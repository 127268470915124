import React from "react";
import { AdminProvider } from "../../../app/services/hooks/useAdmin";
import { ClientProvider } from "../../../app/services/hooks/useClient";

export const UserListProvider = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  return (
    <AdminProvider>
      <ClientProvider>
        <>{children}</>
      </ClientProvider>
    </AdminProvider>
  );
};
