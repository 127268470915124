import { entitySelector } from "app/utility/entitySelectors";
import React from "react";
import { selectMapper } from "../../utility/selectMapper";
import { useGetDriversQuery } from "../wialonServices";

export const useDriversProvider = (dateRequested?: Date) => {
	const [selected, setSelected] = React.useState<null | Date>(null);
	const { data, error, isFetching: isLoading, refetch } = useGetDriversQuery();

	const selectors = entitySelector(data);

	if (data) {
		selectors.selectOptions = selectMapper(data.entities, {
			label: ["n"],
			value: "id",
			extra: "jp",
		});
	}

	return React.useMemo(() => {
		return {
			data: data,
			error,
			isLoading,
			refetch,
			...selectors,
			selected,
			setSelected,
		};
	}, [data, error, isLoading, refetch, selectors, selected, setSelected]);
};

export const DriversContext = React.createContext<ReturnType<typeof useDriversProvider> | null>(null);

export const DriversProvider = ({ children }: { children: React.ReactNode }) => {
	const props = useDriversProvider();
	return <DriversContext.Provider value={props}>{children}</DriversContext.Provider>;
};

export const useDrivers = () => {
	return React.useContext(DriversContext) as ReturnType<typeof useDriversProvider>;
};
