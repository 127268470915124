import { Box, Drawer, Toolbar } from "@mui/material";
import Components from "features/Navigation/Components";
import { useAuth } from "../../../app/services/hooks/useAuth";
export const AppDrawer = () => {
  const drawerWidth = 240;
  const { role } = useAuth();

  return (
    <Drawer
      variant='permanent'
      sx={{
        width: drawerWidth,
        flexShrink: 0,
        [`& .MuiDrawer-paper`]: { width: drawerWidth, boxSizing: "border-box" },
      }}
    >
      <Toolbar />
      <Box sx={{ overflow: "auto" }}>
        <Components role={role} />
      </Box>
    </Drawer>
  );
};
