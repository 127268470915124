import styled from "@emotion/styled";
import { Button, ButtonProps, Theme, Typography } from "@mui/material";

export const SubmitButton = ({
  text,
  handleModal,
  ...props
}: SubmitButtonProps & { text: string; handleModal?: () => void }) => {
  const options = { ...props };
  if (handleModal) {
    options.onClick = handleModal;
  }
  return (
    <StyledSubmitButton
      {...options}
      fullWidth
      variant='contained'
      color='primary'
    >
      <Typography variant='button'>{text}</Typography>
    </StyledSubmitButton>
  );
};

export default SubmitButton;

type SubmitButtonProps = ButtonProps & { theme?: Theme };

const StyledSubmitButton = styled(Button)(({ theme }: SubmitButtonProps) => ({
  margin: theme?.spacing(3, 0, 2),
  backgroundColor: theme?.palette.secondary.main,
}));
