import { UserDto } from "app/services/dto/user.dto";
import {
  useCreateUserMutation, useGetDriverQuery
} from "app/services/userServices";
import { entitySelector } from "app/utility/entitySelectors";
import React from "react";
import { selectMapper } from "../../utility/selectMapper";

export const useDriver = () => {
  const { data, error, isFetching: isLoading, refetch } = useGetDriverQuery();


  const [createUser] = useCreateUserMutation();


  const selectors = entitySelector<UserDto>(data || undefined);

  if (data) {
    selectors.selectOptions = selectMapper(data.entities, {
      label: ["name"],
      value: "id",
      assignedVehicles: "assignedVehicles"
    })
  }

  return React.useMemo(() => {
    return {
      data,
      error,
      isLoading,
      refetch,
      createUser,
      ...selectors,
    };
  }, [data, error, isLoading, refetch, createUser, selectors]);
};
